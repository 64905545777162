<template>
  <div class="energy-report-container">
    <el-row :gutter="24">
      <!-- 左侧树形控件 -->
      <el-col :span="6">
        <el-card>
          <template v-slot:header>
            <span class="card-header">{{ apiModule.projectName }} 历史查询</span>
          </template>

          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="分组" name="group">
              <!-- 按分组 -->
              <!-- 添加搜索输入框 -->
              <el-input
                  placeholder="模糊搜索"
                  v-model="searchQuery"
                  class="tree-search"
                  @input="handleSearch"
                  clearable
              />

              <!-- 添加滚动条 -->
              <div class="tree-container">
                <el-tree
                    ref="tree"
                    :data="treeData"
                    :props="defaultProps"
                    highlight-current
                    node-key="id"
                    @node-click="handleNodeClick"
                    default-expand-all
                    :expand-on-click-node="false"
                    show-checkbox
                    @check-change="handleCheckChange"
                    :filter-node-method="filterNode"
                />
              </div>
            </el-tab-pane>


            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                  v-model="selectedDevices"
                  multiple
                  clearable
                  collapse-tags
                  placeholder="请选择设备"
                  popper-class="custom-header"
                  :max-collapse-tags="1"
                  :filter-method="filterEquipment"
                  filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                        v-model="checkAllDevices"
                        :indeterminate="indeterminateDevices"
                        @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                        v-model="energyType"
                        size="small"
                        class="radio-group-right"
                        @change="fetchDeviceData"
                    >
                      <el-radio-button label="电" value="电"/>
                      <el-radio-button label="水" value="水"/>
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                    v-for="device in devices"
                    :key="device.id"
                    :label="device.name"
                    :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>

      <!-- 右侧查询条件和表格 -->
      <el-col :span="18">
        <div class="query-container">
          <!-- 查询条件 -->
          <el-form :model="queryForm" inline>
            <el-form-item class="left-align">
              <el-select
                  v-model="energyType"
                  placeholder="选择用能类型"
                  style="width: 60px; margin-right: 10px"
                  @change="fetchDeviceData"
              >
                <el-option label="水" value="水"></el-option>
                <el-option label="电" value="电"></el-option>
              </el-select>
              <el-select
                  v-model="timeFrame"
                  placeholder="选择时间类型"
                  filterable
                  allow-create
                  default-first-option
                  style="width: 60px; margin-right: 5px"
                  @change="updateHeaders"
              >
                <el-option label="日" value="day"></el-option>
                <el-option label="月" value="month"></el-option>
                <el-option label="年" value="year"></el-option>
              </el-select>
              <div v-if="timeFrame === 'day'" class="date-picker">
                <el-date-picker
                    v-model="dateRange"
                    type="date"
                    placeholder="开始日期"
                    style="width: 115px"
                    @change="updateHeaders"
                />
                <el-select
                    v-model="startTime"
                    placeholder="开始时间"
                    style="width: 90px; margin-left: 10px"
                    @change="updateHeaders"
                >
                  <el-option
                      v-for="hour in 24"
                      :key="hour"
                      :label="`${(hour - 1).toString().padStart(2, '0')}时`"
                      :value="hour - 1"
                  ></el-option>
                </el-select>
                <el-date-picker
                    v-model="dateRangeb"
                    type="date"
                    placeholder="结束日期"
                    style="width: 130px; margin-left: 10px"
                    @change="updateHeaders"
                />
                <el-select
                    v-model="endTime"
                    placeholder="结束时间"
                    style="width: 100px; margin-left: 5px"
                    @change="updateHeaders"
                >
                  <el-option
                      v-for="hour in 24"
                      :key="hour"
                      :label="`${(hour - 1).toString().padStart(2, '0')}时`"
                      :value="hour - 1"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="timeFrame === 'month'" class="date-picker">
                <el-date-picker
                    v-model="selectedMonth"
                    type="month"
                    placeholder="选择月"
                    @change="updateHeaders"
                />
                <el-button
                    @click="prevMonth"
                    style="margin-left: 5px"
                    v-if="showNavigationButtons_Month"
                >上一月
                </el-button
                >
                <el-button @click="nextMonth" v-if="showNavigationButtons_Month"
                >下一月
                </el-button
                >
              </div>
              <div v-else-if="timeFrame === 'year'" class="date-picker">
                <el-date-picker
                    v-model="selectedYear"
                    type="year"
                    placeholder="选择年"
                    @change="updateHeaders"
                />
                <el-button
                    @click="prevYear"
                    style="margin-left: 5px"
                    v-if="showNavigationButtons_year"
                >上一年
                </el-button
                >
                <el-button @click="nextYear" v-if="showNavigationButtons_year"
                >下一年
                </el-button
                >
              </div>
              <el-form-item style="margin-left: 5px">
                <el-button type="primary" @click="fetchTableData"
                >查询
                </el-button
                >
                <el-button type="primary" @click="exportToExcel"
                >导出
                </el-button
                >
              </el-form-item>
            </el-form-item>
          </el-form>
        </div>
        <el-col :span="24" v-if="showChart" class="chart-controls">
          <el-button-group>
            <el-button
                :type="yearlyBarButtonType"
                @click="toggleChartType('柱状', 'yearly')"
            >柱状
            </el-button
            >
            <el-button
                :type="yearlyLinButtonType"
                @click="toggleChartType('曲线', 'yearly')"
            >曲线
            </el-button
            >
          </el-button-group>
        </el-col>

        <!-- ECharts 图表 -->
        <v-chart
            ref="chartRef"
            v-if="showChart"
            :option="chartOptions"
            style="width: 100%; height: 400px"
        />

        <!-- 表格 -->
        <el-table
            v-loading="loading"
            :data="tableData"
            :summary-method="getSummaries"
            show-summary
            border
            :header-cell-style="{ backgroundColor: '#f5f5f5', color: '#666' }"
        >
          <el-table-column
              prop="deviceName"
              :label="nameLabel"
              width="150"
              sortable
              fixed
          />
          <el-table-column
              v-for="header in headers"
              :key="header.prop"
              :prop="header.prop"
              :label="header.label"
              sortable
          >
          </el-table-column>
          <el-table-column prop="total" label="合计"/>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";
import axios from "axios";
import ApiConfig from "@/APIConfig.js";
import apiModule from "@/APIConfig.js";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
]);
export default {
  name: "EnergyReport",
  computed: {
    apiModule() {
      return apiModule
    }
  },
  data() {
    return {
      showChart: false, // 图表初始隐藏
      yearlyBarButtonType: "primary", // 初始柱状图按钮类型
      yearlyLinButtonType: "default", // 初始曲线图按钮类型
      chartType: "bar", // 初始图表类型
      energyType: "电", //默认选择电
      timeFrame: "day", // 默认选择日
      dateRange: "", // 开始日期选择器
      dateRangeb: "", // 结束日期选择器
      startTime: 0, // 开始时间
      endTime: 23, // 结束时间
      selectedMonth: "", // 月份选择器
      selectedYear: "", // 年份选择器
      showNavigationButtons_day: false, // 控制上/下一日显示
      showNavigationButtons_Month: false, // 控制上/下一月显示
      showNavigationButtons_year: false, // 控制上/下一年显示
      treeData: [],
      devices: [], // 新增: 设备数据
      devicesAll: [], // 新增: 设备数据
      selectedDevices: [], // 新增: 选中的设备
      checkAllDevices: false, // 新增: 设备全选
      indeterminateDevices: false, // 新增: 设备全选不确定状态
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryForm: {
        date: "",
      },
      tableData: [],
      headers: [],
      selectedNodes: [], // 选中的节点
      loading: false, // 控制加载状态
      chartOptions: {}, // ECharts 图表选项
      searchQuery: "", // 搜索查询
      activeName: "group", //默认选中分组
      nameLabel: "分组名称", // 动态表格列标题
    };
  },
  created() {
    this.fetchTreeData();
    this.fetchDeviceData(); // 新增: 调用设备数据的获取方法
  },
  // watch: {
  //   searchQuery(val) {
  //     console.log(val)
  //     // this.$refs.tree.filter(val);
  //   },
  // },
  methods: {
    //分组过滤
    handleSearch() {
      // 手动触发过滤
      this.$refs.tree.filter(this.searchQuery);
    },
    //设备搜索自定义
    filterEquipment(value) {
      this.devices = this.devicesAll.filter(device => device.name.includes(value))
    },
    handleClick() {
      if (this.activeName === "group") {
        this.nameLabel = "设备名称";
      } else if (this.activeName === "equipment") {
        this.nameLabel = "分组名称";
      }
    },
    toggleChartType(type, timeframe) {
      if (type === "柱状") {
        this.chartType = "bar";
        this.yearlyBarButtonType = "primary";
        this.yearlyLinButtonType = "default";
      } else if (type === "曲线") {
        this.chartType = "line";
        this.yearlyBarButtonType = "default";
        this.yearlyLinButtonType = "primary";
      }

      if (timeframe === "yearly") {
        this.fetchTableData();
      }
    },
    async fetchTreeData() {
      try {
        this.loading = true;
        const response = await axios.get(
            ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAlllocation
        );
        this.treeData = this.convertToTree(response.data.data);
      } catch (error) {
        this.$message.error("获取区域数据失败");
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchDeviceData() {

      let params = {};
      params = {
        energyType: this.energyType,
        projectId: ApiConfig.projectId,
      };
      // 在切换用能类型之前清空已选设备
      this.selectedDevices = [];
      this.checkAllDevices = false;
      this.indeterminateDevices = false;
      try {
        const response = await axios.get(
            ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAllequipment,
            {params: params}
        );
        if (response.data.code === 0) {
          this.devices = response.data.data;
          this.devicesAll = response.data.data;
        } else {
          this.$message.error("获取设备数据失败");
        }
      } catch (error) {
        this.$message.error("获取设备数据失败");
        console.error(error);
      }
    },
    convertToTree(data) {
      const map = {};
      data.forEach((item) => {
        map[item.id] = {...item, label: item.name, children: []}; // 确保使用正确的属性名称
      });

      const tree = [];
      data.forEach((item) => {
        if (item.parent_id === null) {
          tree.push(map[item.id]);
        } else {
          const parent = map[item.parent_id];
          if (parent) {
            parent.children.push(map[item.id]);
          }
        }
      });

      return tree;
    },
    handleNodeClick(node) {
      console.log("Node clicked:", node);
    },
    handleCheckChange() {
      const selectedNodes = this.$refs.tree.getCheckedNodes();
      this.selectedNodes = selectedNodes.map((node) => ({
        id: node.id,
        treeNodeId: node.$treeNodeId,
        label: node.label, // 确保正确属性
      }));
      console.log("Selected nodes:", this.selectedNodes);
    },
    handleCheckAllDevices(val) {
      this.selectedDevices = val ? this.devices.map((device) => device.id) : [];
      this.indeterminateDevices = false;
    },
    filterNode(value, data) {
      // 节点过滤方法：如果搜索值为空，显示所有节点；否则按节点名进行模糊匹配
      if (!value) {
        data.disabled = false; // 清空搜索时所有节点可见且可选
        return true;
      }
      const isMatch = data.label.includes(value);
      data.disabled = !isMatch; // 未匹配的节点设置为不可选
      return isMatch; // 返回 true 时显示节点，返回 false 时隐藏
    },
    updateHeaders() {
      this.tableData = [];
      let headers = [];
      if (this.timeFrame === "day") {
        const startDateTime = new Date(
            `${this.formatDate(this.dateRange, "date")}T${String(
                this.startTime
            ).padStart(2, "0")}:00:00`
        );
        const endDateTime = new Date(
            `${this.formatDate(this.dateRangeb, "date")}T${String(
                this.endTime
            ).padStart(2, "0")}:00:00`
        );

        if (
            !isNaN(startDateTime) &&
            !isNaN(endDateTime) &&
            startDateTime <= endDateTime
        ) {
          const hoursDiff = (endDateTime - startDateTime) / 1000 / 60 / 60;
          for (let i = 0; i <= hoursDiff; i++) {
            const currentHour = new Date(
                startDateTime.getTime() + i * 60 * 60 * 1000
            );
            const label = `${currentHour.getFullYear()}-${(
                currentHour.getMonth() + 1
            )
                .toString()
                .padStart(2, "0")}-${currentHour
                .getDate()
                .toString()
                .padStart(2, "0")} ${currentHour
                .getHours()
                .toString()
                .padStart(2, "0")}时`;
            const prop = `${currentHour.getFullYear()}-${String(
                currentHour.getMonth() + 1
            ).padStart(2, "0")}-${String(currentHour.getDate()).padStart(
                2,
                "0"
            )} ${String(currentHour.getHours()).padStart(2, "0")}时`;
            headers.push({label, prop});
          }
        }
      } else if (this.timeFrame === "month") {
        if (this.selectedMonth) {
          const year = new Date(this.selectedMonth).getFullYear();
          const month = new Date(this.selectedMonth).getMonth() + 1;
          const daysInMonth = new Date(year, month, 0).getDate();
          headers = Array.from({length: daysInMonth}, (_, i) => ({
            label: `${i + 1}号`,
            prop: `${String(i + 1).padStart(2)}号`.trim(),
          }));
        }
      } else if (this.timeFrame === "year") {
        headers = Array.from({length: 12}, (_, i) => ({
          label: `${i + 1}月`,
          prop: `${(i + 1).toString().padStart(2)}月`.trim(),
        }));
      }
      this.headers = headers;
    },

    fetchTableData() {
      this.updateHeaders();

      let startDateTime, endDateTime;
      if (this.timeFrame === "day") {
        startDateTime = new Date(
            `${this.formatDate(this.dateRange, "date")}T${String(
                this.startTime
            ).padStart(2, "0")}:00:00Z`
        );
        endDateTime = new Date(
            `${this.formatDate(this.dateRangeb, "date")}T${String(
                this.endTime
            ).padStart(2, "0")}:00:00Z`
        );
      } else if (this.timeFrame === "month") {
        const year = new Date(this.selectedMonth).getFullYear();
        const month = new Date(this.selectedMonth).getMonth();
        startDateTime = new Date(Date.UTC(year, month, 1, 0, 0, 0));
        endDateTime = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
      } else if (this.timeFrame === "year") {
        const year = new Date(this.selectedYear).getFullYear();
        startDateTime = new Date(Date.UTC(year, 0, 1, 0, 0, 0));
        endDateTime = new Date(Date.UTC(year, 11, 31, 23, 59, 59));
      }

      console.log("Start Date Time:", startDateTime);
      console.log("End Date Time:", endDateTime);
      console.log("Selected node:", this.selectedNodes);

      if (isNaN(startDateTime) || isNaN(endDateTime)) {
        this.$message.error("无效的日期或时间");
      }

      if (this.activeName === "group") {
        if (this.selectedNodes.length === 0) {
          this.$message.warning("请先选择一个或多个分组");
        } else if (this.activeName === "equipment") {
          if (this.selectedDevices.length === 0) {
            this.$message.warning("请先选择一个或多个设备");
          }
        }
      }

      const body = {
        energyType: this.energyType,
        timeType: this.timeFrame,
        year: new Date(this.selectedYear).getFullYear(),
        startTime: startDateTime.toISOString().slice(0, 19).replace("T", " "),
        endTime: endDateTime.toISOString().slice(0, 19).replace("T", " "),
      };

      let apiurl;
      if (this.activeName === "group") {
        this.selectedNodes = this.$refs.tree.getCheckedNodes();
        if (this.selectedNodes.length === 0) {
          this.$message.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getEnergyReportForms;
        body.customRegionIds = this.selectedNodes.map((node) => node.id);
      } else if (this.activeName === "equipment") {
        if (this.selectedDevices.length === 0) {
          this.$message.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getEnergyReportEquipmentForms;
        body.equipmentIds = this.selectedDevices;
      }

      console.log("Request body:", body);

      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;

      axios
          .post(url, body)
          .then((response) => {
            if (
                response.data.code === 0 &&
                response.data.message === "该类型设备数为0"
            ) {
              const nodeNames = this.selectedNodes
                  .map((node) => node.label)
                  .join(", ");
              this.$message.warning(`${nodeNames} 暂无数据`);
              return;
            }

            const responseData = response.data.data;

            // 将转换后的数据赋给this.tableData
            this.tableData = this.transformData(responseData);
            this.chartOptions = this.createChartOptions(responseData);

            this.showNavigationButtons_day = this.timeFrame === "day";
            this.showNavigationButtons_Month = this.timeFrame === "month";
            this.showNavigationButtons_year = this.timeFrame === "year";

            this.showChart = true; // 显示图表
          })
          .catch((error) => {
            console.error("API error:", error);
            this.$message.error("数据加载失败，请稍后重试");
          });
    },
    transformData(responseData) {
      const transformedData = responseData.map((item) => {
        const transformedItem = {deviceName: item.deviceName};

        // 遍历每个时间点的数据
        Object.entries(item).forEach(([key, value]) => {
          if (key !== "deviceName") {
            transformedItem[key] = !isNaN(parseFloat(value))
                ? parseFloat(value).toFixed(3)
                : "0.000";
          }
        });

        // 计算合计值
        transformedItem.total = Object.values(transformedItem)
            .filter((val) => !isNaN(val))
            .reduce((acc, val) => acc + parseFloat(val), 0)
            .toFixed(3); // 格式化为两位小数

        return transformedItem;
      });
      console.log("Headers:", this.headers);
      console.log("Transformed Data:", transformedData);
      return transformedData;
    },
    createChartOptions(responseData) {
      const seriesData = responseData.map((item) => {
        return {
          name: item.deviceName,
          type: this.chartType,
          smooth: true,
          data: this.headers.map((header) => item[header.prop] || "0.000"),
        };
      });

      const unit = this.energyType === "水" ? "m³" : "kW·h";
      const chartOptions = {
        title: {},
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let result = params[0].name + "<br/>";
            params.forEach((item) => {
              result +=
                  item.marker +
                  item.seriesName +
                  ": " +
                  item.value +
                  ` ${unit}<br/>`;
            });
            return result;
          },
        },
        legend: {
          type: 'scroll', // 启用滚动模式
          data: responseData.map((item) => item.deviceName),
          left: 'center', // 图例位置居中
          right: '10%', // 滚动条在右侧，确保图例项能左右滚动
          top: '5%', // 设置图例距离顶部的距离
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.headers.map((header) => header.label),
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
        },
        series: seriesData.length
            ? seriesData
            : [
              {
                name: "No Data",
                type: this.chartType,
                smooth: true,
                data: this.headers.map(() => "0.000"),
              },
            ],
      };

      return chartOptions;
    },
    formatDate(date, format = "date") {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const day = d.getDate().toString().padStart(2, "0");

      if (format === "date") {
        return `${year}-${month}-${day}`;
      } else if (format === "month") {
        return `${year}-${month}`;
      } else if (format === "year") {
        return `${year}`;
      }
    },
    prevDay() {
      const currentDate = new Date(this.dateRange);
      currentDate.setDate(currentDate.getDate() - 1);
      this.dateRange = this.formatDate(currentDate);
      this.fetchTableData();
    },
    nextDay() {
      const currentDate = new Date(this.dateRange);
      currentDate.setDate(currentDate.getDate() + 1);
      this.dateRange = this.formatDate(currentDate);
      this.fetchTableData();
    },
    prevMonth() {
      const currentDate = new Date(this.selectedMonth);
      if (!isNaN(currentDate.getTime())) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const prevMonth = month === 1 ? 12 : month - 1;
        const prevYear = month === 1 ? year - 1 : year;
        this.selectedMonth = `${prevYear}-${prevMonth
            .toString()
            .padStart(2, "0")}`;
      }
      this.fetchTableData();
    },
    nextMonth() {
      const currentDate = new Date(this.selectedMonth);
      if (!isNaN(currentDate.getTime())) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const nextMonth = month === 12 ? 1 : month + 1;
        const nextYear = month === 12 ? year + 1 : year;
        this.selectedMonth = `${nextYear}-${nextMonth
            .toString()
            .padStart(2, "0")}`;
      }
      this.fetchTableData();
    },
    prevYear() {
      const currentDate = new Date(this.selectedYear);
      if (!isNaN(currentDate.getTime())) {
        const prevYear = currentDate.getFullYear() - 1;
        this.selectedYear = `${prevYear}`;
      }
      this.fetchTableData();
    },
    nextYear() {
      const currentDate = new Date(this.selectedYear);
      if (!isNaN(currentDate.getTime())) {
        const nextYear = currentDate.getFullYear() + 1;
        this.selectedYear = `${nextYear}`;
      }
      this.fetchTableData();
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      const unit = this.energyType === "水" ? "m³" : "kW·h";
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = `汇总/${unit}`;
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          const total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + value;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = total.toFixed(3); // 保留2位小数
        } else {
          sums[index] = "-";
        }
      });
      return sums;
    },
    exportToExcel() {
      //根据参数条件去后端查询要导出的数据
      // 获取选中的节点
      this.selectedNodes = this.$refs.tree.getCheckedNodes();

      // 计算时间范围
      let startDateTime, endDateTime;
      if (this.timeFrame === "day") {
        startDateTime = new Date(
            `${this.formatDate(this.dateRange, "date")}T${String(
                this.startTime
            ).padStart(2, "0")}:00:00Z`
        );
        endDateTime = new Date(
            `${this.formatDate(this.dateRangeb, "date")}T${String(
                this.endTime
            ).padStart(2, "0")}:00:00Z`
        );
      } else if (this.timeFrame === "month") {
        const year = new Date(this.selectedMonth).getFullYear();
        const month = new Date(this.selectedMonth).getMonth();
        startDateTime = new Date(Date.UTC(year, month, 1, 0, 0, 0));
        endDateTime = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
      } else if (this.timeFrame === "year") {
        const year = new Date(this.selectedYear).getFullYear();
        startDateTime = new Date(Date.UTC(year, 0, 1, 0, 0, 0));
        endDateTime = new Date(Date.UTC(year, 11, 31, 23, 59, 59));
      }

      console.log("Start Date Time:", startDateTime);
      console.log("End Date Time:", endDateTime);
      console.log("Selected node:", this.selectedNodes);

      if (isNaN(startDateTime) || isNaN(endDateTime)) {
        this.$message.error("无效的日期或时间");
        return;
      }

      // 构建请求体
      const requestBody = {
        energyType: this.energyType,
        customRegionIds: this.selectedNodes.map((node) => node.id),
        timeType: this.timeFrame,
        year: new Date(this.selectedYear).getFullYear(),
        startTime: startDateTime.toISOString().slice(0, 19).replace("T", " "),
        endTime: endDateTime.toISOString().slice(0, 19).replace("T", " "),
      };

      let apiurl;
      if (this.activeName === "group") {
        if (this.selectedNodes.length === 0) {
          this.$message.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getEnergyReportFormsExcel;
        requestBody.customRegionIds = this.selectedNodes.map((node) => node.id);
      } else if (this.activeName === "equipment") {
        if (this.selectedDevices.length === 0) {
          this.$message.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getEnergyEquipmentReportFormsExcel;
        requestBody.equipmentIds = this.selectedDevices;
      }

      console.log("Request body:", requestBody);

      // 发送 POST 请求到后端
      axios
          .post(ApiConfig.apiBaseUrl + apiurl, requestBody, {
            responseType: "blob",
          })
          .then((response) => {
            // 处理导出成功的情况
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = "能耗报表.xlsx";
            link.click();
          })
          .catch((error) => {
            // 处理导出失败的情况
            console.error("导出失败:", error);
            this.$message.error("导出失败，请稍后重试");
          });
    },
  },
};
</script>

<style scoped>
.energy-report-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.query-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.left-align {
  display: flex;
  align-items: center;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.tree-search {
  margin-bottom: 10px;
}

.chart-controls {
  text-align: right;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.radio-group-right {
  margin-left: auto;
}

.card-header {
  font-weight: bold;
}
.tree-container {
  max-height: 480px; /* 限制最大高度 */
  overflow-y: auto; /* 纵向滚动 */
  border: 1px ; /* 可选：添加边框 */
  padding: 1px;
}
/* 设置滚动条的宽度 */
.tree-container::-webkit-scrollbar {
  width: 2px; /* 竖向滚动条宽度 */
}
/* 设置滚动条轨道的样式 */
.tree-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* 设置滚动条滑块的样式 */
.tree-container::-webkit-scrollbar-thumb {
  background: #ccc; /* 更浅的颜色 */
  border-radius: 10px;
}
</style>