<template>
  <div class="common-layout">
    <el-container>
      <el-aside>
        <el-card shadow="always">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="分组" name="group">
              <!-- 按分组 -->
              <!-- 添加搜索输入框 -->
              <el-input
                  placeholder="模糊搜索"
                  v-model="searchQuery"
                  class="tree-search"
                  clearable
                  @input="handleSearch"
              />
              <div class="tree-container">
              <el-tree
                  ref="tree"
                  :data="filteredTreeData"
                  :props="defaultProps"
                  highlight-current
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  show-checkbox
                  @check-change="handleCheckChange"
                  :filter-node-method="filterNode"
              />
              </div>
              <!-- 按分组end -->
            </el-tab-pane>

            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                  v-model="selectedDevices"
                  multiple
                  clearable
                  collapse-tags
                  placeholder="请选择设备"
                  popper-class="custom-header"
                  :max-collapse-tags="1"
                  :filter-method="filterEquipment"
                  filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                        v-model="checkAllDevices"
                        :indeterminate="indeterminateDevices"
                        @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                        v-model="energyType"
                        size="small"
                        class="radio-group-right"
                        @change="energyTypeHandleClick"
                    >
                      <el-radio-button label="电" value="电"/>
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                    v-for="device in devices"
                    :key="device.id"
                    :label="device.name"
                    :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-aside>
      <el-main>
        <el-card shadow="always">
          <el-tabs
              v-model="activeNameTable"
              class="demo-tabs"
              @tab-click="handleClickTable"
          >
            <el-tab-pane label="日报" name="day">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                </el-select>
                <el-date-picker
                    v-model="dateRange"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="到"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期范围"
                    style="margin-right: 10px"
                />
                <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelDay">查询</el-button>
                <el-button type="primary" :icon="Download" @click="exportTable">导出</el-button>
                <el-checkbox v-model="selectedPointed"  style="left: 18px">尖</el-checkbox>
                <el-checkbox v-model="selectedPeak" >峰</el-checkbox>
                <el-checkbox v-model="selectedFlat" >平</el-checkbox>
                <el-checkbox v-model="selectedValley">谷</el-checkbox>
                <el-checkbox v-model="selectedDeepValley" >深谷</el-checkbox>
              </div>
            </el-tab-pane>
            <el-tab-pane label="月报" name="month">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                </el-select>
                <el-date-picker
                    v-model="month"
                    type="month"
                    placeholder="选择月"
                    style="margin-right: 10px"
                />
                <el-button type="primary" :icon="ArrowLeft" @click="handlePreviousMonth">上一月</el-button>
                <el-button type="primary" @click="handleNextMonth">
                  下一月
                  <el-icon style="margin-left: 5px">
                    <ArrowRight/>
                  </el-icon>
                </el-button>
                <el-button type="primary" :icon="Search"  @click="queryCustomRegionPeakValleyLevelMonth">查询</el-button>
                <el-button type="primary" :icon="Download"  @click="exportTable">导出</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="年报" name="year">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                </el-select>
                <el-date-picker
                    v-model="year"
                    type="year"
                    placeholder="选择年"
                    style="margin-right: 10px"
                    value-format="YYYY"
                />
                <el-button type="primary" :icon="ArrowLeft" @click="handlePreviousYear">上一年</el-button>
                <el-button type="primary" @click="handleNextYear">
                  下一年
                  <el-icon style="margin-left: 5px">
                    <ArrowRight/>
                  </el-icon>
                </el-button>
                <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelYear">查询</el-button>
                <el-button type="primary" :icon="Download" @click="exportTable">导出</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>

          <!-- 图表弹窗 -->
          <el-dialog
              v-model="dialogVisible"
              title="电量/金额分析饼图"
              width="1000"
          >
            <el-row>
              <el-col :span="12">
                <el-card shadow="always">
                  <div>电量占比</div>
                  <el-divider></el-divider>
                  <div ref="electricityChart" style="height: 250px"></div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card shadow="always">
                  <div>金额占比</div>
                  <el-divider></el-divider>
                  <div ref="amountChart" style="height: 250px"></div>
                </el-card>
              </el-col>
            </el-row>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="dialogVisible = false">
                  确定
                </el-button>
              </div>
            </template>
          </el-dialog>

          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              show-selection
              show-summary
              :header-cell-style="setHeaderStyle"
          >
            <el-table-column type="selection" width="55" align="center" />

            <el-table-column prop="date" label="回路名称" align="center" width="100px" sortable />

            <!-- 尖 -->
            <el-table-column v-if="selectedPointed"  label="尖" align="center">
              <el-table-column prop="尖.electricity" label="电量" align="center" sortable />
              <el-table-column prop="尖.price" label="单价" align="center" sortable />
              <el-table-column prop="尖.amount" label="金额" align="center" sortable />
            </el-table-column>

            <!-- 峰 -->
            <el-table-column v-if="selectedPeak" label="峰" align="center">
              <el-table-column prop="峰.electricity" label="电量" align="center" sortable />
              <el-table-column prop="峰.price" label="单价" align="center" sortable />
              <el-table-column prop="峰.amount" label="金额" align="center" sortable />
            </el-table-column>

            <!-- 平 -->
            <el-table-column v-if="selectedFlat" label="平" align="center">
              <el-table-column prop="平.electricity" label="电量" align="center" sortable />
              <el-table-column prop="平.price" label="单价" align="center" sortable />
              <el-table-column prop="平.amount" label="金额" align="center" sortable />
            </el-table-column>

            <!-- 谷 -->
            <el-table-column  v-if="selectedValley" label="谷" align="center">
              <el-table-column prop="谷.electricity" label="电量" align="center" sortable />
              <el-table-column prop="谷.price" label="单价" align="center" sortable />
              <el-table-column prop="谷.amount" label="金额" align="center" sortable />
            </el-table-column>

            <!-- 深谷 -->
            <el-table-column v-if="selectedDeepValley" label="深谷" align="center">
              <el-table-column prop="深谷.electricity" label="电量" align="center" sortable />
              <el-table-column prop="深谷.price" label="单价" align="center" sortable />
              <el-table-column prop="深谷.amount" label="金额" align="center" sortable />
            </el-table-column>

            <!-- 合计 -->
            <el-table-column label="合计" align="center">
              <el-table-column prop="totalElectricity" label="电量/kwh" align="center" />
              <el-table-column prop="totalAmount" label="金额" align="center" />
            </el-table-column>
          </el-table>

        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, watch, onMounted} from "vue";
import {
  ArrowLeft,
  ArrowRight,
  Search,
  Download,
} from "@element-plus/icons-vue";
import apiModule from "@/APIConfig";
import ApiConfig from "@/APIConfig";
import axios from "axios";
import * as XLSX from 'xlsx';
import {ElMessage} from "element-plus";
const selectedPointed = ref(true);
const selectedPeak = ref(true);
const selectedFlat = ref(true);
const selectedValley = ref(true);
const selectedDeepValley = ref(true);
const setHeaderStyle = ({ column }) => {
  const colors = {
    "尖": "#FF4D4F",
    "峰": "#FA8C16",
    "平": "#52C41A",
    "谷": "#1890FF",
    "深谷": "#096DD9"
  };
  return colors[column.label] ? { backgroundColor: colors[column.label], color: "#ffffff" } : {};
};
// 组件引用
const tree = ref(null);
const checkAllDevices = ref("false"); // 新增: 设备全选
//分组数据
const treeData = ref([]);
//分组模糊查询
const searchQuery = ref("");
// 过滤后的树数据
const filteredTreeData = ref(treeData.value);
//设备数据
const selectedDevices = ref([])
const indeterminateDevices = ref(false)
const energyType = ref('电')
const devices = ref([])
const devicesAll = ref([])

// 分组设备 标签页状态
const activeName = ref("group");
// 标签页状态
const activeNameTable = ref("day");

// 日期控件状态
const dateRange = ref("");
// const month = ref([]);
// const year = ref([]);
const month = ref(new Date()); // 默认当前月
const year = ref(new Date().getFullYear().toString()); // 默认当前年
const dialogVisible = ref(false);//图表对话框
// 用于存储选中的表格行
const selectedData = ref([]);
//图表
const electricityChart = ref(null);
const amountChart = ref(null);
//表格数据
const currentTableData = ref([])

//搜索数据
const filterEquipmentValue = ref();
//设备搜索自定义
const filterEquipment = (value) =>{
  filterEquipmentValue.value = value
  devices.value = devicesAll.value.filter(device => device.name.includes(value))
}

// 树形控件配置
const defaultProps = ref({
  children: "children",
  label: "name",
});
// 全选和取消全选逻辑
const handleCheckAllDevices = (val) => {
  selectedDevices.value = val ? devices.value
      .map(device => device.id) : []
  indeterminateDevices.value = false
}
//切换水电
const energyTypeHandleClick = (val) => {
   fetchDeviceData(val);
}

// 处理分组搜索输入
const handleSearch = () => {
  if (tree.value) {
    tree.value.filter(searchQuery.value);
  }
};
// 过滤节点的方法
const filterNode = (value, data) => {
  // 节点过滤方法：如果搜索值为空，显示所有节点；否则按节点名进行模糊匹配
  if (!value) {
    data.disabled = false; // 清空搜索时所有节点可见且可选
    return true;
  }
  const isMatch = data.name.includes(value);
  data.disabled = !isMatch; // 未匹配的节点设置为不可选
  return isMatch; // 返回 true 时显示节点，返回 false 时隐藏
};
// 分组树形控件相关方法
const handleCheckChange = () => {

};
// 切换标签页时触发
const handleClick = async () => {

};
// 年月日切换标签页时触发
const handleClickTable = async () => {

};
// 处理上一月
const handlePreviousMonth = () => {
  const currentMonth = new Date(month.value);
  currentMonth.setMonth(currentMonth.getMonth() - 1);
  month.value = currentMonth;
};

// 处理下一月
const handleNextMonth = () => {
  const currentMonth = new Date(month.value);
  currentMonth.setMonth(currentMonth.getMonth() + 1);
  month.value = currentMonth;
};

// 处理上一年
const handlePreviousYear = () => {
  year.value = (parseInt(year.value) - 1).toString();
};

// 处理下一年
const handleNextYear = () => {
  year.value = (parseInt(year.value) + 1).toString();
};



// 表格选中项变化时触发
const handleSelectionChange = (rows) => {
  selectedData.value = rows
};

//导出
const exportTable = () => {
  // 将源数据转换为目标格式
  const transformedData = [];

  currentTableData.value.forEach(item => {
    const row = {
      "分组/设备名称": item.date,
      "尖_电量": item.尖 ? item.尖.electricity : 0,
      "尖_单价": item.尖 ? item.尖.price : 1,
      "尖_金额": item.尖 ? item.尖.amount : 0,

      // 判断是否存在 '峰' 属性，不存在则赋值 0
      "峰_电量": item.峰 ? item.峰.electricity : 0,
      "峰_单价": item.峰 ? item.峰.price : 1,
      "峰_金额": item.峰 ? item.峰.amount : 0,

      // 判断是否存在 '平' 属性，不存在则赋值 0
      "平_电量": item.平 ? item.平.electricity : 0,
      "平_单价": item.平 ? item.平.price : 1,
      "平_金额": item.平 ? item.平.amount : 0,

      // 判断是否存在 '谷' 属性，不存在则赋值 0
      "谷_电量": item.谷 ? item.谷.electricity : 0,
      "谷_单价": item.谷 ? item.谷.price : 1,
      "谷_金额": item.谷 ? item.谷.amount : 0,

      // 深谷默认为 0
      "深谷_电量": item.深谷 ? item.深谷.electricity : 0,
      "深谷_单价": item.深谷 ? item.深谷.price : 1,
      "深谷_金额": item.深谷 ? item.深谷.amount : 0,

      // 合计值也要检查是否存在
      "合计_电量": item.totalElectricity || 0,
      "合计_金额": item.totalAmount || 0
    };
    transformedData.push(row);
  });

// 额外添加合计行
  transformedData.push({
    "分组/设备名称": "合计",
    "尖_电量": transformedData.reduce((acc, row) => acc + row["尖_电量"], 0),
    "尖_单价": transformedData.reduce((acc, row) => acc + row["尖_单价"], 0),
    "尖_金额": transformedData.reduce((acc, row) => acc + row["尖_金额"], 0),
    "峰_电量": transformedData.reduce((acc, row) => acc + row["峰_电量"], 0),
    "峰_单价": transformedData.reduce((acc, row) => acc + row["峰_单价"], 0),
    "峰_金额": transformedData.reduce((acc, row) => acc + row["峰_金额"], 0),
    "平_电量": transformedData.reduce((acc, row) => acc + row["平_电量"], 0),
    "平_单价": transformedData.reduce((acc, row) => acc + row["平_单价"], 0),
    "平_金额": transformedData.reduce((acc, row) => acc + row["平_金额"], 0),
    "谷_电量": transformedData.reduce((acc, row) => acc + row["谷_电量"], 0),
    "谷_单价": transformedData.reduce((acc, row) => acc + row["谷_单价"], 0),
    "谷_金额": transformedData.reduce((acc, row) => acc + row["谷_金额"], 0),
    "深谷_电量": transformedData.reduce((acc, row) => acc + row["深谷_电量"], 0),
    "深谷_单价": transformedData.reduce((acc, row) => acc + row["深谷_单价"], 0),
    "深谷_金额": transformedData.reduce((acc, row) => acc + row["深谷_金额"], 0),
    "合计_电量": transformedData.reduce((acc, row) => acc + row["合计_电量"], 0),
    "合计_金额": transformedData.reduce((acc, row) => acc + row["合计_金额"], 0)
  });

// 导出为 Excel
  const ws = XLSX.utils.json_to_sheet(transformedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  let name = "";
  if (activeNameTable.value === "day") {
    name = dateRange.value[0]+"~"+dateRange.value[1]+"分时段用电报表";
  } else if (activeNameTable.value === "month"){
    name = month.value.getFullYear()+"-"+(month.value.getMonth() + 1)+"分时段用电报表";
  } else if (activeNameTable.value === "year"){
    name = year.value+"分时段用电报表";
  }

  XLSX.writeFile(wb, name+".xlsx");
};



//日报查询
const queryCustomRegionPeakValleyLevelDay = async () => {
  if (!dateRange.value?.[0]) {
    ElMessage.warning("请选择起始日期");
    return;
  }
  const startTime = dateRange.value[0]+" "+"00:00:00";
  const endTime = dateRange.value[1]+" "+"23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group"){
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    if (!checkedNodeIds[0]) {
      ElMessage.warning("请先选择一个或多个分组");
    }
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  }else if (activeName.value === "equipment"){
    if (selectedDevices.value.length === 0) {
      ElMessage.warning("请先选择一个或多个设备");
      return;
    }
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataEquipment(params);
  }

}
//月报查询
// 格式化为YYYY-MM-DD HH:MM:SS
const formatDateToFullString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份补0
  const day = date.getDate().toString().padStart(2, '0');          // 天补0
  const hours = date.getHours().toString().padStart(2, '0');        // 小时补0
  const minutes = date.getMinutes().toString().padStart(2, '0');    // 分钟补0
  const seconds = date.getSeconds().toString().padStart(2, '0');    // 秒补0
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const queryCustomRegionPeakValleyLevelMonth = async () => {
  // 获取当前月份的第一天
  const startOfMonth = new Date(month.value.getFullYear(), month.value.getMonth(), 1);
  // 获取当前月份的最后一天
  const endOfMonth = new Date(month.value.getFullYear(), month.value.getMonth() + 1, 0);
  const startTime = formatDateToFullString(startOfMonth);
  let endTime = formatDateToFullString(endOfMonth);
  const date = endTime.split(' ');
  endTime = date[0] + " " + "23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group"){
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    if (!checkedNodeIds[0]) {
      ElMessage.warning("请先选择一个或多个分组");
    }
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  }else if (activeName.value === "equipment"){
    if (selectedDevices.value.length === 0) {
      ElMessage.warning("请先选择一个或多个设备");
      return;
    }
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }

    await fetchPeakValleyLevelDataEquipment(params);
  }

}
//年报查询
const queryCustomRegionPeakValleyLevelYear = async () => {

  const startTime = year.value+"-01-01 00:00:00";
  const endTime = year.value+"-12-31 23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group"){
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    if (!checkedNodeIds[0]) {
      ElMessage.warning("请先选择一个或多个分组");
    }
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  }else if (activeName.value === "equipment"){
    if (selectedDevices.value.length === 0) {
      ElMessage.warning("请先选择一个或多个设备");
      return;
    }
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataEquipment(params);
  }

}
// 页面加载时触发接口调用
onMounted(async () => {
  await fetchDeviceData("电");
  await getAlllocation();
});
//获取设备
const fetchDeviceData = async (energyType) => {
  try {
    let params = {
      energyType: energyType,
      projectId: ApiConfig.projectId,
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllequipment}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    devices.value = response.data.data
    devicesAll.value = response.data.data
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//返回结果转换树结构
const convertToTree = (data) => {
  const map = {};
  data.forEach((item) => {
    map[item.id] = {...item, label: item.name}; // 确保使用正确的属性名称
  });

  const tree = [];
  data.forEach((item) => {
    if (item.parent_id === null) {
      tree.push(map[item.id]);
    } else {
      const parent = map[item.parent_id];
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(map[item.id]);
      }
    }
  });

  return tree;
};
//获取分组
const getAlllocation = async () => {
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}/${apiModule.endpoints.getAlllocation}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    treeData.value = convertToTree(data.data);
    filteredTreeData.value = treeData.value;
    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//分时段查询(分组)
const fetchPeakValleyLevelDataCustomRegionPeak = async (params) => {
  try {
    const response = await axios.post(`${apiModule.apiBaseUrl}/${apiModule.endpoints.queryCustomRegionPeakValleyLevel}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 直接从 response.data 中获取数据
    currentTableData.value = response.data.data
    return response.data.data
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//分时段查询(设备)
const fetchPeakValleyLevelDataEquipment = async (params) => {
  try {
    const response = await axios.post(`${apiModule.apiBaseUrl}/${apiModule.endpoints.queryEquipmentPeakValleyLevel}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 直接从 response.data 中获取数据
    currentTableData.value = response.data.data
    return response.data.data
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};

// 当 dialogVisible 变化时初始化图表
watch(dialogVisible, async () => {

});
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tree-container {
  max-height: 480px; /* 限制最大高度 */
  overflow-y: auto; /* 纵向滚动 */
  border: 1px; /* 可选：添加边框 */
  padding: 1px;
}

/* 设置滚动条的宽度 */
.tree-container::-webkit-scrollbar {
  width: 2px; /* 竖向滚动条宽度 */
}

/* 设置滚动条轨道的样式 */
.tree-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* 设置滚动条滑块的样式 */
.tree-container::-webkit-scrollbar-thumb {
  background: #ccc; /* 更浅的颜色 */
  border-radius: 10px;
}

</style>
  