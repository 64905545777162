const readApiBaseUrl = async () => {
    try {
        const response = await fetch('/apiBaseUrl.txt');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data.trim();
    } catch (err) {
        console.error("Error fetching apiBaseUrl.txt file:", err);
        throw err;
    }
};

const apiModule = {
    menuItems: '',
    projectName: '',
    apiBaseUrl: '',
    endpoints: {
        // 能耗相关接口
        totalEnergyConsumption: "energyQuery/getTotalEnergyConsumption", // 获取总能耗
        TodayEnergyConsumption: "energyQuery/getEnergyConsumptionBetween", // 获取今日能耗
        getEnergyConsumptionDay: "energyQuery/getEnergyConsumptionDay", // 当日逐时图表数据
        getEnergyConsumptionMonth: "energyQuery/getEnergyConsumptionMonth", // 当月逐日
        getEnergyConsumptionYear: "energyQuery/getEnergyConsumptionYear", // 当年逐月
        getEnergyRanking: "energyQuery/getEnergyRanking", // 用能排行

        // 区域相关接口
        getAlllocation: "location/getAll", // 查询所有区域
        getAllStrategy: "location/getAllStrategy", // 根据策略id,查询所有策略绑定的区域
        getDepartmentsByParentId: "location/getParentId", // 根据parentId获取分组
        getByIdEnergy: "location/getByIdEnergy", // 根据parentId获取分组
        updateDepartment: "location/update", // 更新分组
        deleteDepartment: "location/delete", // 删除分组
        newDepartment: "location/insert", // 新增分组
        insertLocationBatch: "location/insertBatch", // 批量导入区域
        getById: "location/getById", //根据区域查询全部设备
        getByIdAll: "location/getByIdAll", //根据区域查询全部设备
        getAllEquipment: "location/getAllEquipment", //查询全部设备
        updateEditBindingDevice: "locationEquipment/updateEditBindingDevice", // 更改绑定抄表类型
        queryAllDataBasedOnDeviceId: "locationEquipment/queryAllDataBasedOnDeviceId", // 根据设备id查询全部数据


        // 设备相关接口
        getAllequipment: "equipment/getAllEquipment", // 查询全部设备
        getAllNoequipment: "equipment/get", // 获取所有未绑定的设备
        insertequipment: "locationEquipment/insert", // 新增分组设备绑定
        deleteequipment: "locationEquipment/delete", // 解除分组设备绑定
        deleteBatch: "locationEquipment/deleteBatch", // 批量解除区域设备绑定
        insertList: "locationEquipment/insertList", // 批量绑定分组设备

        // 能耗报表相关接口
        getEnergyReportForms: "energyReportForms/getEnergyReportForms", // 能耗报表查询
        getEnergyReportEquipmentForms: "energyReportForms/getEnergyReportEquipmentForms", // 能耗报表按设备查询
        getEnergyReportFormsExcel: "energyReportForms/getEnergyReportFormsExcel", // 能耗报表导出
        getEnergyEquipmentReportFormsExcel: "energyReportForms/getEnergyReportEquipmentFormsExcel", // 能耗设备报表导出

        // 集中复制相关接口
        getCentralizedCopying: "centralizedCopying/getCentralizedCopying", // 根据分组集合查询用能
        getCentralizedCopyingExcel: "centralizedCopying/getCentralizedCopyingExcel", // 用能集合导出
        getCentralizedCopyingEquipment: "centralizedCopying/getCentralizedCopyingEquipment", // 根据设备集合查询用能
        getCentralizedCopyingEquipmentExcel: "centralizedCopying/getCentralizedCopyingEquipmentExcel", // 根据设备集合导出用能

        // 年同比相关接口
        getYearYoyQuery: "yearYoyQuery/getYearYoyQuery", // 年同比查询
        getYearYoyQueryExcel: "yearYoyQuery/getYearYoyQueryExcel", // 年同比导出
        getEquipmentYearYoyQuery: "yearYoyQuery/getEquipmentYearYoyQuery", // 设备年同比查询
        getEquipmentYearYoyQueryExcel: "yearYoyQuery/getEquipmentYearYoyQueryExcel", // 设备年同比导出

        // 月环比相关接口
        getMonthOnMonth: "monthOnMonthQuery/getMonthOnMonth", // 环比查询
        getMonthOnMonthExcel: "monthOnMonthQuery/getMonthOnMonthExcel", // 环比查询导出
        getEquipmentMonthOnMonth: "monthOnMonthQuery/getEquipmentMonthOnMonth", // 根据设备环比查询
        getEquipmentMonthOnMonthExcel: "monthOnMonthQuery/getEquipmentMonthOnMonthExcel", // 根据设备环比查询导出

        // 策略管理相关接口
        getAllLocation: "strategy/getAll", // 分组绑定策略
        getCustomRegionStrategy: "strategy/customRegionStrategy", // 分组绑定策略
        queryCustomRegionStrategy: "strategy/queryCustomRegionStrategy", // 查询策略绑定的分组id集合
        deleteRule: "strategy/deleteRule", // 删除策略规则
        deleteStrategy: "strategy/deleteStrategy", // 删除策略
        insertRule: "strategy/insertRule", // 新增策略规则
        insertStrategy: "strategy/insertStrategy", // 新增策略
        strategyAll: "strategy/strategyAll", // 查询全部策略
        strategyRule: "strategy/strategyRule", // 查询对应策略的规则
        updateRule: "strategy/updateRule", // 修改策略规则
        updateStrategy: "strategy/updateStrategy", // 更新策略


        //峰谷平查询
        queryCustomRegionPeakValleyLevel: "peakValleyLevel/queryCustomRegionPeakValleyLevel",//分时段查询(分组)
        queryEquipmentPeakValleyLevel: "peakValleyLevel/queryEquipmentPeakValleyLevel",//分时段查询(设备)
        rerunPeakValleyLevel: "peakValleyLevel/rerunPeakValleyLevel",//峰谷平策略重写
        //峰谷平原始记录
        queryStrategyPeakValleyLevelVariableEnergyHour: "strategy-peak-valley/getById",//根据策略ID设备时间(yyyy-MM-dd)获取记录
        updateStrategyPeakValleyLevelVariableEnergyHour: "strategy-peak-valley/update",//更新记录
        //固定查询
        getAllFixedCustomRegion: "fixed/getAllFixedCustomRegion",//查询全部绑定了固定策略的分组
        getAllEquipmentFixed: "fixed/getAllEquipmentFixed",//查询全部绑定了固定策略的分组设备
        queryCustomRegionFixed: "fixed/queryCustomRegionFixed",//固定查询(分组)
        queryEquipmentFixed: "fixed/queryEquipmentFixed",//固定查询(设备)

        //账单查询
        getAllPeakValleyLevel: "billConfig/getAllPeakValleyLevel",//查询峰谷平账单
        getAllFixed: "billConfig/getAllFixed",//查询固定账单
        updateBillDictionary: "billConfig/updateBillDictionary",//设置账单日
        download: "billConfig/download",//下载账单
        getBillDictionary: "billConfig/getBillDictionary",//查询账单日
        queryCustomRegionIds: "billConfig/queryCustomRegionIds",//查询绑定的分组id集合
        insertBillConfig: "billConfig/insertBillConfig",//设置生成账单的分组

        //手动抄表
        queryRecords: "manual/queryRecords",//查询记录表
        depositManualAndInfluxdb: "manual/depositManualAndInfluxdb",//批量插入
        deleteRecords: "manual/deleteRecords",//根据id，时间删除influxdb里的数据
        allExcel111: "manual/allExcel",//导出全部设备的初始化表格

        //换表记录
        switchingTableRecords: "fixed/switchingTableRecords",//根据时间，查询换表记录
        insertSwitchingTableRecord: "fixed/insertSwitchingTableRecord",//插入记录
        deleteSwitchingTableRecordById: "fixed/deleteSwitchingTableRecordById",//删除记录（根据 ID）
        updateSwitchingTableRecord: "fixed/updateSwitchingTableRecord",//更新记录（根据 ID）
        selectLatestRecordByEquipmentIdAndTime: "fixed/selectLatestRecordByEquipmentIdAndTime",//根据设备id 查询最近的一条
        selectAllEquipment: "fixed/selectAllEquipment",//查询全部设备
        deleteSwitchingTableRecordsByIds: "fixed/deleteSwitchingTableRecordsByIds",//根据id，批量删除
        switchingTableRecordsNew: "fixed/switchingTableRecordsNew",//根据时间，查询最新的一条换表记录

        //固定换表【换表
        getAllSwitchingTableEquipment: "fixed/getAllSwitchingTableEquipment",//查询全部绑定了固定策略的分组（且换表）的设备
        querySwitchingTableEquipmentFixed: "fixed/querySwitchingTableEquipmentFixed",//查询全部绑定了固定策略的分组（且换表）的设备
        //分时段用电【换表
        querySwitchingTableEquipmentTime: "peakValleyLevel/querySwitchingTableEquipmentTime",//查询全部换表记录的（设备）
        queryEquipmentPeakValleyLevelTime: "peakValleyLevel/queryEquipmentPeakValleyLevelTime",//分时段查询换表记录(设备)
       //手动修复
        manuallyRepairRecordQueryRecords: "manuallyRepairRecord/queryRecords",//查询全部修复记录
        manuallyRepairRecordAdd: "manuallyRepairRecord/add",//手动修复
        queryHistoricalDeviceData: "manuallyRepairRecord/queryHistoricalDeviceData",//根据设备名，时间查询历史数据库值


    },
    async initialize() {
        const data = await readApiBaseUrl();
        const lines = data.split('\n');
        const apiBaseUrlLine = lines.find(line => line.startsWith('ServeUrl='));
        const projectIdLine = lines.find(line => line.startsWith('projectId='));
        const projectNameLine = lines.find(line => line.startsWith('projectName='));
        if (apiBaseUrlLine) {
            this.apiBaseUrl = apiBaseUrlLine.split('=')[1].replace(/[";]/g, '').trim();
        }
        // this.apiBaseUrl = "http://"+window.location.hostname+":"+window.location.port+"/api/"//自适应ip端口
        if (projectIdLine) {
            this.projectId = projectIdLine.split('=')[1].replace(/[";]/g, '').trim();
        }
        if (projectNameLine) {
            apiModule.projectName = projectNameLine.split('=')[1].replace(/[";]/g, '').trim();
        }

        //菜单
        // 查找 <menuItems> 和 </menuItems> 的位置
        const startIndex = data.indexOf('<menuItems>') + '<menuItems>'.length;
        const endIndex = data.indexOf('</menuItems>');

      // 截取标签之间的内容
        if (startIndex !== -1 && endIndex !== -1) {
            const menuItemsJson = data.slice(startIndex, endIndex).trim();
            try {
                if (menuItemsJson){
                    apiModule.menuItems = JSON.parse(menuItemsJson);
                }
            } catch (e) {
                console.error("解析 JSON 时发生错误:", e);
            }
        } else {
            console.log("没有找到 <menuItems> 标签内容");
        }
    },
};
(async () => {
    await apiModule.initialize();
    // console.log(apiModule.apiBaseUrl);
})();

export default apiModule;
