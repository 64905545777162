<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-card shadow="always">
          <div class="query-container">
            <el-select
                v-model="energyTypeStrategy"
                placeholder="选择策略"
                style="width: 150px; margin-right: 10px"
                clearable
                filterable
                @change="handleStrategyChange"
            >
              <el-option
                  v-for="item in optionsStrategy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-select
                v-model="energyTypeStrategyType"
                placeholder="选择类型"
                style="width: 150px; margin-right: 10px"
                clearable
                filterable
                multiple
            >
              <el-option
                  v-for="item in strategyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-select
                v-model="energyTypeCustomRegion"
                placeholder="选择分组"
                style="width: 170px; margin-right: 10px"
                clearable
                filterable
                multiple
            >
              <el-option
                  v-for="item in optionsCustomRegion"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-select
                v-model="energyTypeEquipment"
                placeholder="选择设备"
                style="width: 170px; margin-right: 10px"
                clearable
                filterable
                multiple
                @visible-change="handleDropdownVisibleChange"
            >
              <el-option
                  v-for="item in optionsEquipment"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>

            <el-select
                v-model="timeTypeDate"
                placeholder="选择时间类型"
                style="width: 150px; margin-right: 10px"
                clearable
                filterable
            >
              <el-option label="自定义" value="自定义"/>
              <el-option label="日" value="日"/>
              <el-option label="月" value="月"/>
            </el-select>

            <!-- 日期选择框，根据时间类型动态变化 -->
            <el-date-picker
                v-model="dateRange"
                value-format="YYYY-MM-DD"
                :type="datePickerType"
                :placeholder="datePickerPlaceholder"
                style="margin-right: 10px"
                range-separator="至"
                :start-placeholder="startPlaceholder"
                :end-placeholder="endPlaceholder"
            />
            <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakDay">查询</el-button>
          </div>
          <!-- 编辑弹窗 -->
          <el-dialog
              title="编辑数据"
              v-model="addDialogVisible"
              :destroy-on-close="true"
              draggable
              width="20%"
          >
            <el-form :style="{ display: 'flex', 'justify-content': 'center' }">
              <el-form-item label="用能">
                <el-input-number
                    v-model="dialogData.value"
                    :precision="3"
                    :step="0.1"
                />
              </el-form-item>
            </el-form>


            <template v-slot:footer>
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="updateStrategyPeakValleyLevel">保存</el-button>
            </template>
          </el-dialog>
          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @row-click="handleRowClick"
              show-selection
              show-summary
          >
            <el-table-column prop="peakValleyLevelStrategyName" label="策略" align="center" width="100px" sortable/>
            <el-table-column prop="nyr" label="日期" align="center" sortable/>
            <el-table-column prop="energy" label="用能" align="center" sortable/>
            <el-table-column prop="createTime" label="创建时间" align="center" sortable/>
            <el-table-column prop="lastTime" label="更新时间" align="center" sortable/>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                    type="text"
                    size="small"
                    @click="showEditDialog(scope.row)"
                >编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <!-- 分页组件放右边 -->
          <div class="pagination-container">
            <!-- 分页组件 -->
            <el-pagination
                class="pagination"
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 50, 100,1000]"
                :total="totalRecords"
                layout="total, prev, pager, next, jumper, sizes"
                @current-change="handlePageChange"
                @size-change="handlePageSizeChange"
            />
          </div>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, watch, onMounted} from "vue";
import {
  Search
} from "@element-plus/icons-vue";
import apiModule from "@/APIConfig";
import axios from "axios";
import {ElMessage} from "element-plus";
//全部策略
const optionsStrategy = ref([]);
//全部分组
const optionsCustomRegion = ref([]);
//分组下的设备
const optionsEquipment = ref([])

const energyTypeStrategy = ref()
const energyTypeStrategyType = ref()
const energyTypeCustomRegion = ref([])
const energyTypeEquipment = ref([])
const strategyOptions = ref([
  {value: '峰', label: '峰'},
  {value: '谷', label: '谷'},
  {value: '平', label: '平'}
]);
//编辑弹窗
const addDialogVisible = ref(false)
//弹窗数据
const dialogData = ref({
  value: ""
});

// 时间选择【时间】
// 时间类型（日、月、自定义）
const timeTypeDate = ref('日');
const dateRange = ref(null);
const datePickerType = ref('date');
const datePickerPlaceholder = ref('选择日期');
const startPlaceholder = ref('开始日期');  // 默认开始日期占位符
const endPlaceholder = ref('结束日期');  // 默认结束日期占位符
// 监听 timeTypeDate 的变化，根据时间类型更新 datePickerType 和 datePickerPlaceholder
watch(timeTypeDate, (newValue) => {
  if (newValue === '日') {
    datePickerType.value = 'date';  // 日期选择器显示日期
    datePickerPlaceholder.value = '选择日期';  // 日期占位符
  } else if (newValue === '月') {
    datePickerType.value = 'month';  // 日期选择器显示月份
    datePickerPlaceholder.value = '选择月份';  // 月份占位符
  } else if (newValue === '自定义') {
    datePickerType.value = 'daterange';  // 日期选择器显示日期范围
    startPlaceholder.value = '开始日期';  // 自定义开始日期占位符
    endPlaceholder.value = '结束日期';  // 自定义结束日期占位符
    console.log(datePickerPlaceholder.value);
  }
  // 清空已选择的日期或日期范围
  dateRange.value = null;
});


// 日期控件状态
const dialogVisible = ref(false);//图表对话框
// 用于存储选中的表格行
const selectedData = ref([]);
//表格数据
const currentTableData = ref([])


// 表格选中项变化时触发
const handleRowClick = (rows) => {
  selectedData.value = rows
};
//设备下拉框显示时触发
const handleDropdownVisibleChange = async (visible) => {
  if (visible) {
    // 当下拉框显示时触发
    await fetchDeviceDataLocation(energyTypeCustomRegion.value);
  }
};

//【分页】
const currentPage = ref(1);
const pageSize = ref(10); // 每页10条数据
const totalRecords = ref(0); // 总记录数
const handlePageChange = (page) => {// 处理每页条数变化
  currentPage.value = page;
  queryCustomRegionPeakValleyLevelDay(); // 每次分页改变时重新加载数据
};
const handlePageSizeChange = (size) => {// 处理页条数变化
  pageSize.value = size;
  queryCustomRegionPeakValleyLevelDay(); // 每次页大小改变时重新加载数据
};
const queryCustomRegionPeakDay = async () => {
  currentPage.value=1;
  queryCustomRegionPeakValleyLevelDay();
}

//查询
const queryCustomRegionPeakValleyLevelDay = async () => {
  if (isNaN(Number(energyTypeStrategy.value)) || energyTypeStrategy.value === "") {
    ElMessage.warning("请选择策略！！");
    return;
  }
  try {
    let params = {
      equipmentId: energyTypeEquipment.value,
      nyr: dateRange.value,
      strategyId: Number(energyTypeStrategy.value),
      page: currentPage.value,
      limit: pageSize.value,
      strategyType: energyTypeStrategyType.value,
      timeType: timeTypeDate.value
    };

// 打印检查请求参数
    console.log(params);

    const response = await axios.post(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryStrategyPeakValleyLevelVariableEnergyHour}`,
        params  // 通过 params 字段传递查询参数
    );

    currentTableData.value = response.data.data.records
    totalRecords.value = response.data.data.total || 0;
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
}
//更新记录
const updateStrategyPeakValleyLevel = async () => {
  addDialogVisible.value = false
  try {
    let params = {
      variableId: selectedData.value.variableId,
      strategyPeakValleyLevelId: selectedData.value.strategyPeakValleyLevelId,
      nyr: selectedData.value.nyr,
      energyValue: dialogData.value.value
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.updateStrategyPeakValleyLevelVariableEnergyHour}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0) {
      ElMessage.success(response.data.message)
    } else {
      ElMessage.error(response.data.message)
    }

    return response.data.message;
  } catch (err) {
    ElMessage.error("更新失败")
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
}
// 页面加载时触发接口调用
onMounted(async () => {
  await strategyAll();
});
//获取分组下全部设备
const fetchDeviceDataLocation = async (id) => {
  optionsEquipment.value = [];
  try {
    const response = await axios.post(`${apiModule.apiBaseUrl}${apiModule.endpoints.getByIdAll}`,
        id,  // 直接将 id 数组作为请求体发送
        {
          headers: {
            'Content-Type': 'application/json',  // 告诉后端，发送的是 JSON 数据
          }
        }
    );
    optionsEquipment.value = response.data.data.map((item) => ({
      value: item.id,
      label: item.name
    }))
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
const strategyId = ref(0);
const handleStrategyChange = (val) => {
  console.log('选中的策略:', val);
  strategyId.value = val;
  getAlllocation();
};
//获取分组
const getAlllocation = async () => {
  optionsCustomRegion.value = [];
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllStrategy}?strategyId=${strategyId.value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    optionsCustomRegion.value = data.data.map((item) => ({
      value: item.id,
      label: item.name
    }))
    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//全部策略
const strategyAll = async () => {
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}${apiModule.endpoints.strategyAll}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    optionsStrategy.value = data.data.map((item) => ({
      value: item.id,
      label: item.name
    }))

    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};


// 当 dialogVisible 变化时初始化图表
watch(dialogVisible, async () => {

});
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/*【分页】*/
.pagination {
  float: right; /* 将分页组件浮动到右边 */
  margin-top: 15px; /* 可以根据需要调整分页组件与表格之间的距离 */
  margin-bottom: 15px;
}
</style>
