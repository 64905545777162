<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-card shadow="always">

          <div class="query-container">
            <el-input v-model="input" style="width: 200px" placeholder="请输入设备名" clearable/>
            <!-- 时间选择-->
            <el-date-picker
                v-model="value"
                type="daterange"
                range-separator="到"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="defaultTime"
                size="medium"
                style="width: 280px; margin-left: 20px;"
            />
            <el-button type="primary" :icon="Search" @click="handleQueryClick"
                       style="margin-left: 20px;">查询
            </el-button>
            <!-- 手动修复按钮 -->
            <el-button type="primary" @click="handleFileClick" style="margin-left: 15px; width: 80px;">手动修复
            </el-button>
            <el-checkbox v-model="selectedDeviceId" style="top: 3px; left: 15px">设备ID</el-checkbox>
            <el-checkbox v-model="selectedAccessIp" style="top: 3px;">访问IP</el-checkbox>
          </div>

          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @row-click="handleRowClick"
              @selection-change="handleSelectionChange"
              show-selection
          >
            <el-table-column prop="id" label="id" align="center" width="100px" sortable/>
            <el-table-column prop="device_name" label="设备名称" align="center" sortable/>
            <el-table-column v-if="selectedDeviceId" prop="device_id" label="设备ID" align="center" sortable />
            <el-table-column prop="start_time" label="开始时间" align="center" sortable/>
            <el-table-column prop="end_time" label="结束时间" align="center" sortable/>
            <el-table-column prop="start_value" label="开始值" align="center" sortable/>
            <el-table-column prop="end_value" label="结束值" align="center" sortable/>
            <el-table-column prop="created_at" label="创建时间" align="center" sortable/>
            <el-table-column  v-if="selectedAccessIp" prop="access_ip" label="访问IP" align="center" sortable/>
            <!--            <el-table-column prop="user_id" label="用户ID" align="center" sortable />-->

          </el-table>
          <!-- 分页组件放右边 -->
          <div class="pagination-container">
            <!-- 分页组件 -->
            <el-pagination
                class="pagination"
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 50, 100,1000]"
                :total="totalRecords"
                layout="total, prev, pager, next, jumper, sizes"
                @current-change="handlePageChange"
                @size-change="handlePageSizeChange"
            />
          </div>
          <!--【手动修复】-->
          <el-dialog
              title="手动修复"
              v-model="addDialogVisible"
              width="500px"
              :destroy-on-close="true"
              draggable
          >
            <el-form  :model="ruleForm" :rules="rules"  ref="formRef" label-width="100px" >
              <!-- 设备 -->
              <el-form-item label="设备名" prop="device_name" class="form-item">
                <el-select
                    v-model="ruleForm.device_name"
                    placeholder="请选择设备名"
                    @change="handleDeviceChange"
                    clearable
                    filterable
                    allow-create
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in equipmentList"
                      :key="item.equipmentId"
                      :label="item.equipmentName"
                      :value="item.equipmentName"
                  />
                </el-select>
              </el-form-item>


              <!-- 开始时间 -->
              <el-form-item label="开始时间" prop="start_time" required class="form-item">
                <el-col :span="11">
                  <!-- 日期选择器 -->
                  <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="ruleForm.start_time"
                      style="width: 100%;"
                  />
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <!-- 时间选择器 -->
                  <el-time-picker
                      placeholder="选择时间"
                      v-model="ruleForm.start_time"
                      style="width: 100%;"
                      format="HH:mm:ss"
                      :disabled-minutes="disabledMinutes"
                  />
                </el-col>
              </el-form-item>

              <!-- 结束时间 -->
              <el-form-item label="结束时间" prop="end_time" required class="form-item">
                <el-col :span="11">
                  <!-- 日期选择器 -->
                  <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="ruleForm.end_time"
                      style="width: 100%;"
                  />
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <!-- 时间选择器 -->
                  <el-time-picker
                      placeholder="选择时间"
                      v-model="ruleForm.end_time"
                      style="width: 100%;"
                      format="HH:mm:ss"
                      :disabled-minutes="disabledMinutes"
                  />
                </el-col>
              </el-form-item>



              <!-- 开始值 -->
              <el-form-item label="开始值" prop="start_value" class="form-item">
                <el-input
                    v-model="ruleForm.start_value"
                    placeholder="请输入开始值"
                    clearable
                />
              </el-form-item>

              <!-- 结束值 -->
              <el-form-item label="结束值" prop="end_value" class="form-item">
                <el-input
                    v-model="ruleForm.end_value"
                    placeholder="请输入结束值"
                    clearable
                />
              </el-form-item>
              <el-form-item label="类型：">
                  <el-radio v-model="ruleForm.type" value="均值">均值</el-radio>
<!--                  <el-radio value="中值">中值</el-radio>-->
              </el-form-item>

            </el-form>
            <!-- 底部按钮 -->
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" :loading="isLoading"  @click="submitForm" >确 定</el-button>
              </div>
            </template>
          </el-dialog>

        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, onMounted, watch } from "vue";
import apiModule from "@/APIConfig";
import axios from "axios";
import {Search} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";

// 选中的列
const selectedDeviceId = ref(false);
const selectedAccessIp = ref(false);
// 【页面加载时触发查询】
onMounted(() => {
  queryCustomRegionPeakValleyLevelDay();//查询记录表
});
//【请输入设备名】
const input = ref('')

//【时间选择】
const value = ref('')
const defaultTime = ref < [Date, Date] > ([
  new Date(2000, 1, 1, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59),
])


//【表格】
const selectedData = ref([]);// 用于存储选中的表格行
const currentTableData = ref([])//表格数据
const handleRowClick = (rows) => {// 表格选中项变化时触发
  selectedData.value = rows
};


//【分页】
const currentPage = ref(1);
const pageSize = ref(10); // 每页10条数据
const totalRecords = ref(0); // 总记录数
const handlePageChange = (page) => {// 处理每页条数变化
  currentPage.value = page;
  queryCustomRegionPeakValleyLevelDay(); // 每次分页改变时重新加载数据
};
const handlePageSizeChange = (size) => {// 处理每页条数变化
  pageSize.value = size;
  queryCustomRegionPeakValleyLevelDay(); // 每次页大小改变时重新加载数据
};

//【查询按钮】
const handleQueryClick = async () => {
  currentPage.value = 1;
  const equipmentName = input.value?.trim() || defaultTime.value; // 获取设备名，去除空格，若为空则使用默认值
  const [startTime, endTime] = (value.value && value.value.length > 0) ? value.value : [null, null]; // 获取时间范围
// 如果设备名和时间范围都为空，才进行警告
  if (!equipmentName && !startTime && !endTime) {
    ElMessage.warning("请输入设备名或选择时间范围！");
  }
  await queryCustomRegionPeakValleyLevelDay();
};
//【查询记录表】
const queryCustomRegionPeakValleyLevelDay = async () => {
  try {
    const equipmentName = input.value || defaultTime.value; // 假设你用 `input` 变量绑定了设备名  // 获取设备名
    const [startTime, endTime] = value.value && value.value.length > 0 ? value.value : [null, null]; // 获取时间范围
    const formattedStartTime = startTime ? formatDate(startTime) : null;// 格式化时间，假设使用的是 moment 或类似的库，如果没有可以直接使用 Date 对象
    const formattedEndTime = endTime ? formatDate(endTime) : null;
    console.log(formattedStartTime + "-------------" + formattedEndTime)
    // 请求参数
    const params = {
      page: currentPage.value,  // 当前页码
      limit: pageSize.value,    // 每页显示条数
      equipmentName,            // 设备名
      startTime: formattedStartTime,  // 开始时间
      endTime: formattedEndTime     // 结束时间
    };
    // 发送请求到后端
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.manuallyRepairRecordQueryRecords}`, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0 && response.data.data) {    // 处理返回的数据
      currentTableData.value = response.data.data.records;  // 获取分页后的数据
      totalRecords.value = response.data.data.total || 0;   // 获取总记录数
    } else {
      console.error('API returned an error or empty data');
    }
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
const formatDate = (date) => {//【查询记录表】开始时间-结束时间 时间转换
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，+1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

//【手动修复】
const addDialogVisible = ref(false);
const handleFileClick = () => {// 打开弹窗
  fetchEquipmentData();
  addDialogVisible.value = true;
}

const ruleForm = ref({
  device_name: '',   // 设备名称
  device_id: '',     // 设备ID
  start_time: '',    // 开始时间
  end_time: '',      // 结束时间
  start_value: '',   // 开始值
  end_value: '',     // 结束值
  type:'均值',
});
const rules = {
  device_name: [{ required: true, message: "设备名称不能为空", trigger: "blur" }],
  start_time: [
    { required: true, message: '请选择开始日期和时间', trigger: 'change' },
  ],
  end_time: [
    { required: true, message: '请选择结束日期和时间', trigger: 'change' },
  ],
  start_value: [{ required: true, message: "开始值不能为空", trigger: "blur" }],
  end_value: [{ required: true, message: "结束值不能为空", trigger: "blur" }],
};
const formRef = ref(null);

const equipmentList = ref([]); // 用来存放设备数据
//查询全部设备
const fetchEquipmentData = async () => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.selectAllEquipment}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0) {
      equipmentList.value = response.data.data; // 更新设备列表
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
};

const handleDeviceChange = (value) => {
  console.log("选择设备名变更为:", value); // 验证事件被触发
};
//【手动修复新增弹窗开始时间，结束时间【提示】
watch(
    () => [ruleForm.value.device_name, ruleForm.value.start_time],
    ([deviceName, startTime], [prevDeviceName, prevStartTime]) => {
      // 确保只有在设备名或开始时间发生变化时才触发
      if (deviceName !== prevDeviceName || startTime !== prevStartTime) {
        if (deviceName && startTime) {
          const formattedTime = formatDateTime(startTime);
          queryStartValue(formattedTime);
        }
      }
    }
);

watch(
    () => JSON.stringify([ruleForm.value.device_name, ruleForm.value.end_time]),
    (newValue, oldValue) => {
      if (newValue !== oldValue) {  // 确保是实际变化
        const parsedValues = JSON.parse(newValue);
        const formattedTime = formatDateTime(parsedValues[1]);
        queryEndValue(formattedTime);
      }
    }
);

//根据设备名，时间查询历史数据库值【开始值】
const queryStartValue = async (time) => {
  const selectedDevice = equipmentList.value.find(item => item.equipmentName === ruleForm.value.device_name);
  try {
    const deviceId = selectedDevice.equipmentId;
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryHistoricalDeviceData}`, {
      params: {
        equipmentId: deviceId,
        time: time
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0) {
      console.log(response.data.data.currentValue)
      const data = response.data.data[0];
      console.log(data)
      ruleForm.value = {
        ...ruleForm.value, // 保留现有值
        start_value: data.currenValue,     // 开始值
      };
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
};
//根据设备名，时间查询历史数据库值【结束值】
const queryEndValue = async (time) => {
  const selectedDevice = equipmentList.value.find(item => item.equipmentName === ruleForm.value.device_name);
  try {
    const deviceId = selectedDevice.equipmentId;
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryHistoricalDeviceData}`, {
      params: {
        equipmentId: deviceId,
        time: time
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0) {
      const data = response.data.data[0];
      console.log(data)
      ruleForm.value = {
        ...ruleForm.value, // 保留现有值
        end_value: data.currenValue,     // 结束值
      };
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
};

const formatDateTime = (dateTime) => {
  if (!dateTime) return "";
  const date = new Date(dateTime);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


const isLoading = ref(false); // 控制按钮加载状态
const closeDialog=() => {
  addDialogVisible.value = false
}
//【手动修复】确认
const submitForm = async () => {
  if (isLoading.value) return; // 防止重复点击

  isLoading.value = true; // 开始加载

  formRef.value.validate(async (valid) => {
    if (valid) {
      try {
        await addConfirm(); // 执行提交操作
        ElMessage.success('操作成功');
      } catch (error) {
        ElMessage.error('操作失败');
      } finally {
        isLoading.value = false; // 结束加载状态，允许再次点击
      }
    } else {
      ElMessage.error('表单验证失败');
      isLoading.value = false; // 结束加载状态
    }
  });
};

const addConfirm = async () => {
  try {
    const name = ruleForm.value.device_name.trim(); // 去除前后空格
    const selectedDevice = equipmentList.value.find(item => item.equipmentName.trim().toLowerCase() === name.toLowerCase()) // 忽略大小写);
    // 请求数据
    console.log(name+"-------------"+selectedDevice)
    const requestData = {
      deviceId:selectedDevice.equipmentId,
      startTime:ruleForm.value.start_time,
      endTime: ruleForm.value.end_time,
      startValue: ruleForm.value.start_value,
      endValue: ruleForm.value.end_value
    };
    const response = await axios.post(`${apiModule.apiBaseUrl}${apiModule.endpoints.manuallyRepairRecordAdd}`, requestData, {
      headers: {'Content-Type': 'application/json'},
    });

    if (response.data.code === 0) {
      if (response.data.message === '操作成功') {
        ElMessage.success(response.data.message);
        addDialogVisible.value = false;
      } else {
        ElMessage.error(response.data.message);
      }
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
  queryCustomRegionPeakValleyLevelDay();
}

// 禁用非 5 分钟的分钟值
const disabledMinutes = () => {
  const allowedMinutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]; // 允许的分钟值
  return Array.from({ length: 60 }, (_, i) => i).filter(
      (minute) => !allowedMinutes.includes(minute) // 过滤出不允许的分钟值
  );
};

</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

/*【分页】*/
.pagination {
  float: right; /* 将分页组件浮动到右边 */
  margin-top: 20px; /* 可以根据需要调整分页组件与表格之间的距离 */
}

.pagination-container {
  display: flex;
  justify-content: flex-end; /* 可改为 flex-start 或 flex-end 调整位置 */
}

</style>
