<template>
  <div class="energy-report-container">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card>
          <template v-slot:header>
            <span class="card-header">{{apiModule.projectName}} 能源分类</span>
          </template>
          <el-menu default-active="ELECTRIC" @select="handleMenuSelect">
            <el-menu-item index="ELECTRIC">电</el-menu-item>
            <el-menu-item index="WATER">水</el-menu-item>
          </el-menu>
        </el-card>
      </el-col>
      <el-col :span="18">
        <!-- 右侧内容 -->
        <div class="query-container">
          <!-- 查询条件 -->
          <el-form :model="queryForm">
            <el-form-item class="left-align">
              <el-button type="primary" @click="showAddDialog"
                >新建策略</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="table-container">
          <el-table
            ref="table"
            :data="currentTableData"
            border
            :expand-row-keys="expandRowKeys"
            row-key="id"
            @expand-change="handleExpand"
            @row-click="handleRowClick"
          >
            <el-table-column type="expand">
              <!-- 展开的内容区域 -->
              <template #default="props">
                <div v-if="loadingRuleId === props.row.id">加载中...</div>
                <div v-else>
                  <div v-if="props.row.rules && props.row.rules.length > 0">
                    <el-row
                      v-for="(rule, index) in props.row.rules"
                      :key="index"
                      :gutter="20"
                      style="margin-bottom: 10px"
                    >
                      <el-col :span="6"
                        ><span v-if="rule.name">规则名称:</span
                        >{{ rule.name }}</el-col
                      >
                      <el-col :span="6">
                        <span v-if="rule.triggerValue"
                          >触发值: {{ rule.triggerValue }}</span
                        >
                        <span v-else-if="rule.triggerTime"
                          >触发时间: {{ rule.triggerTime }}</span
                        >
                        <span v-else></span>
                      </el-col>
                      <el-col :span="6">费用: {{ rule.cost }}</el-col>
                    </el-row>
                  </div>
                  <div v-else>无策略规则</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="策略名称"></el-table-column>
            <el-table-column prop="type" label="策略类型">
              <template #default="scope">
                {{ translateStrategyType(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column label="策略状态">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <el-switch
                    v-model="scope.row.status"
                    :active-value="'1'"
                    :inactive-value="'0'"
                    @change="handleStatusChange(scope.row)"
                  />
                  <span style="margin-left: 10px">
                    {{ scope.row.status === "1" ? "启用" : "禁用" }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="300px">
              <template #default="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showEditDialog(scope.row)"
                  >编辑</el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="showRerunEditDialog(scope.row)"
                >重算</el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="showCustomRegionDialog(scope.row)"
                >绑定分组</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="deleteStrategy(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="totalItems > 0"
            style="float: right; margin-top: 20px"
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="totalItems"
            @current-change="handlePageChange"
          />
        </div>
      </el-col>
    </el-row>

    <!-- 策略重算对话框 -->
    <el-dialog
        title="策略重算"
        v-model="runDialogVisible"
        :destroy-on-close="true"
        draggable
        width="30%"
    >
      <el-date-picker
          v-model="dateRange"
          value-format="YYYY-MM-DD"
          type="daterange"
          range-separator="到"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          placeholder="选择重算日期范围"
          @change="handleDateChange"
          style="margin-right: 10px"
      />
      <div style="display: flex;">
        <el-text class="mx-1" type="warning" style="margin-left: 9px; float: left;">
          {{ runDialogText }}
        </el-text>


      </div>
      <el-progress
          v-if="isLoading"
          :text-inside="true"
          :stroke-width="14"
          :percentage="progress"
          :status="progressStatus"
          striped
          striped-flow
          :duration="10"
          style="margin-left: 10px; margin-right: 17px; margin-top: 8px;"
      />
      <template v-slot:footer>
        <el-button @click="runDialogVisible = false">取 消</el-button>
        <el-button type="primary"  :disabled="isDisabled" @click="rerunPeakValleyLevel">重算</el-button>
      </template>
    </el-dialog>

    <!-- 新建策略对话框 -->
    <el-dialog
      title="新建策略"
      v-model="addDialogVisible"
      :destroy-on-close="true"
      draggable
    >
      <el-form :model="newStrategyForm" label-width="90px">
        <el-form-item label="策略名称">
          <el-input v-model="newStrategyForm.name" />
        </el-form-item>
        <el-form-item label="用能类型">
          <el-radio-group v-model="newStrategyForm.energyType">
            <el-radio label="ELECTRIC">电</el-radio>
            <el-radio label="WATER">水</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="策略类型">
          <el-radio-group v-model="newStrategyForm.type">
<!--            <el-radio label="ladder">阶梯</el-radio>-->
            <el-radio
              v-if="newStrategyForm.energyType === 'ELECTRIC'"
              label="peak_valley_level"
              >峰谷平</el-radio
            >
            <el-radio label="fixed">固定</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="newStrategyForm.type === 'ladder'">
          <el-form-item label="阶梯规则">
            <div class="button-container">
              <el-button type="primary" @click="addRule" size="small"
                >添加阶梯</el-button
              >
            </div>
            <el-table :data="newStrategyForm.rules" style="width: 100%" border>
              <el-table-column prop="name" label="阶梯名称">
                <template v-slot="scope">
                  <el-input v-model="scope.row.name" size="small"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="triggerValue" label="触发值">
                <template v-slot="scope">
                  <el-input
                    v-model="scope.row.triggerValue"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="费用">
                <template v-slot="scope">
                  <el-input-number
                    v-model="scope.row.price"
                    :precision="2"
                    :step="0.1"
                    min="0.10"
                    size="small"
                  />
                </template>
              </el-table-column>
              <el-table-column label="单位">
                <template v-slot="scope">
                  <span>{{ scope.row.unit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="scope">
                  <el-button
                    @click="removeRule(scope.$index)"
                    type="danger"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template
          v-if="
            newStrategyForm.type === 'peak_valley_level' &&
            newStrategyForm.energyType === 'ELECTRIC'
          "
        >
          <el-form-item label="峰谷平规则">
            <div class="button-container">
              <el-button type="primary" @click="addPeakValleyRule" size="small"
                >添加峰谷平</el-button
              >
            </div>
            <el-table
              :data="newStrategyForm.peakValleyRules"
              style="width: 100%"
              border
            >
              <el-table-column prop="name" label="规则名称">
                <template v-slot="scope">
                  <el-select v-model="scope.row.name" size="small" placeholder="请选择">
                    <el-option label="尖" value="尖"></el-option>
                    <el-option label="峰" value="峰"></el-option>
                    <el-option label="谷" value="谷"></el-option>
                    <el-option label="平" value="平"></el-option>
                    <el-option label="深谷" value="深谷"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="triggerTime" label="触发时间">
                <template v-slot="scope">
                  <el-select v-model="scope.row.triggerTime" size="small" style="width: 150px" placeholder="选择时间">
                    <el-option
                        v-for="time in timeOptionsTriggerTime"
                        :key="time"
                        :label="time"
                        :value="time"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="结束时间">
                <template v-slot="scope">
                  <el-select v-model="scope.row.endTime" size="small" style="width: 150px" placeholder="选择时间">
                    <el-option
                        v-for="time in timeOptions"
                        :key="time"
                        :label="time"
                        :value="time"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="费用">
                <template v-slot="scope">
                  <el-input-number
                    v-model="scope.row.price"
                    :precision="2"
                    :step="0.1"
                    min="0.10"
                    size="small"
                  />
                </template>
              </el-table-column>
              <el-table-column label="单位">
                <template v-slot="scope">
                  <span>{{ scope.row.unit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="scope">
                  <el-button
                    @click="removePeakValleyRule(scope.$index)"
                    type="danger"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template v-if="newStrategyForm.type === 'fixed'">
          <el-form-item label="费用">
            <el-input-number
              v-model="newStrategyForm.fixedPrice"
              :precision="2"
              :step="0.1"
              min="0.10"
              size="small"
            />
          </el-form-item>
        </template>
        <el-form-item label="是否启用">
          <el-switch
            v-model="newStrategyForm.enabled"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createStrategy">新 建</el-button>
      </template>
    </el-dialog>

    <!-- 编辑策略对话框 -->
    <el-dialog title="编辑策略" v-model="editDialogVisible">
      <el-form :model="editStrategyForm" label-width="80px">
        <el-form-item label="策略名称">
          <el-input v-model="editStrategyForm.name" />
        </el-form-item>
        <el-form-item label="用能类型">
          <el-radio-group v-model="editStrategyForm.energyType">
            <el-radio label="ELECTRIC">电</el-radio>
            <el-radio label="WATER">水</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="策略类型">
          <el-radio-group v-model="editStrategyForm.type">
<!--            <el-radio label="ladder">阶梯</el-radio>-->
            <el-radio
              v-if="editStrategyForm.energyType === 'ELECTRIC'"
              label="peak_valley_level"
              >峰谷平</el-radio
            >
            <el-radio label="fixed">固定</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="editStrategyForm.type === 'ladder'">
          <el-form-item label="阶梯规则">
            <div class="button-container">
              <el-button type="primary" @click="addEditRule" size="small"
                >添加阶梯</el-button
              >
            </div>
            <el-table :data="editStrategyForm.rules" style="width: 100%" border>
              <el-table-column prop="name" label="阶梯名称">
                <template v-slot="scope">
                  <el-input v-model="scope.row.name" size="small"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="triggerValue" label="触发值">
                <template v-slot="scope">
                  <el-input
                    v-model="scope.row.triggerValue"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="cost" label="费用">
                <template v-slot="scope">
                  <el-input-number
                    v-model="scope.row.cost"
                    :precision="2"
                    :step="0.1"
                    min="0.10"
                    size="small"
                  />
                </template>
              </el-table-column>
              <el-table-column label="单位">
                <template v-slot="scope">
                  <span>{{ scope.row.unit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="scope">
                  <el-button
                    @click="removeEditRule(scope.$index)"
                    type="danger"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template
          v-if="
            editStrategyForm.type === 'peak_valley_level' &&
            editStrategyForm.energyType === 'ELECTRIC'
          "
        >
          <el-form-item label="峰谷平规则">
            <div class="button-container">
              <el-button
                type="primary"
                @click="addEditPeakValleyRule"
                size="small"
                >添加峰谷平</el-button
              >
            </div>
            <el-table :data="editStrategyForm.rules" style="width: 100%" border fit >
              <el-table-column prop="name" label="规则名称" :min-width="55">
                <template v-slot="scope">
                  <el-select v-model="scope.row.name" size="small" placeholder="请选择">
                    <el-option label="尖" value="尖"></el-option>
                    <el-option label="峰" value="峰"></el-option>
                    <el-option label="谷" value="谷"></el-option>
                    <el-option label="平" value="平"></el-option>
                    <el-option label="深谷" value="深谷"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="triggerTime" label="触发时间" :min-width="70">
                <template v-slot="scope">
                  <el-select v-model="scope.row.trigger_time" size="small" placeholder="选择时间">
                    <el-option
                        v-for="time in timeOptionsTriggerTime"
                        :key="time"
                        :label="time"
                        :value="time"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="结束时间" :min-width="100">
                <template v-slot="scope">
                  <el-select v-model="scope.row.end_time" size="small" placeholder="选择时间">
                    <el-option
                        v-for="time in timeOptions"
                        :key="time"
                        :label="time"
                        :value="time"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="cost" label="费用" >
                <template v-slot="scope">
                  <el-input-number
                    v-model="scope.row.cost"
                    :precision="2"
                    :step="0.1"
                    min="0.10"
                    size="small"
                  />
                </template>
              </el-table-column>
              <el-table-column label="单位">
                <template v-slot="scope">
                  <span>{{ scope.row.unit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" >
                <template v-slot="scope">
                  <el-button
                    @click="removeEditPeakValleyRule(scope.$index)"
                    type="danger"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template v-if="editStrategyForm.type === 'fixed'">
          <el-form-item label="费用">
            <el-input-number
              v-model="editStrategyForm.rules[0].cost"
              :precision="2"
              :step="0.1"
              min="0.10"
              size="small"
            />
          </el-form-item>
        </template>
        <el-form-item label="是否启用">
          <el-switch
            v-model="editStrategyForm.enabled"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditedStrategy">保 存</el-button>
      </template>
    </el-dialog>

    <!--绑定分组-->
    <el-dialog title="绑定分组" v-model="isBatchAddDialogCustomRegion" class="batch-add-dialog">
      <div class="transfer-container">

        <div class="equipment-transfer-container">
          <el-transfer
              v-model:modelValue="selectedCustomRegionIds"
              :data="customRegionData"
              :titles="['分组列表', '已绑分组']"
              filterable
              filter-placeholder="请输入分组名称"
              class="equipment-transfer"
          />
        </div>
      </div>
      <template #footer>
        <el-button @click="closeBatchAddCustomRegionDialog">取消</el-button>
        <el-button type="primary" @click="batchAddCustomRegions">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ApiConfig from "@/APIConfig.js";
import axios from "axios";
import {ElMessage} from "element-plus";
import apiModule from "@/APIConfig.js";

export default {
  data() {
    return {
      //进度条
      progress: 0, // 进度条的进度
      isLoading: false, // 控制进度条的显示与隐藏
      progressStatus: "success",
      //策略重算对话框
      runDialogVisible:false,
      dateRange: [],
      runDialogText:"",
      isDisabled:false,
      //表格选中数据
      currentRowData :[],
      //分组右侧数据
      selectedCustomRegionIds:[],
      //分组数据
      customRegionData:[],
      //绑定分组弹窗控制
      isBatchAddDialogCustomRegion: false,
      // 包含今天和第二天的时间段
      timeOptions: this.generateTimeOptions(),
      timeOptionsTriggerTime: this.generateTimeOptionsTriggerTime(),
      activeMenu: "ELECTRIC",
      strategyList: [],
      currentPage: 1,
      pageSize: 10, // 每页显示的数量
      totalItems: 0, // 数据总数
      addDialogVisible: false,
      editDialogVisible: false,
      newStrategyForm: {
        name: "",
        energyType: "ELECTRIC",
        type: "ladder",
        enabled: false,
        rules: [],
        peakValleyRules: [],
        fixedPrice: "",
      },
      editStrategyForm: {
        name: "",
        energyType: "ELECTRIC",
        type: "ladder",
        enabled: false,
        rules: [],
        peakValleyRules: [],
        fixedPrice: "",
        strategyList: [],
      },
      expandRowKeys: [],
    };
  },
  watch: {
    "newStrategyForm.energyType": function (newVal) {
      const unit =
        newVal === "ELECTRIC" ? "kW·h" : newVal === "WATER" ? "m³" : "";
      this.newStrategyForm.rules.forEach((rule) => {
        rule.unit = unit;
      });
      this.newStrategyForm.peakValleyRules.forEach((rule) => {
        rule.unit = unit;
      });
    },
    "editStrategyForm.energyType": function (newVal) {
      const unit =
        newVal === "ELECTRIC" ? "kW·h" : newVal === "WATER" ? "m³" : "";
      this.editStrategyForm.rules.forEach((rule) => {
        rule.unit = unit;
      });
      this.editStrategyForm.peakValleyRules.forEach((rule) => {
        rule.unit = unit;
      });
    },
    editDialogVisible(newVal) {
      if (newVal) {
        const unit =
          this.editStrategyForm.energyType === "ELECTRIC"
            ? "kW·h"
            : this.editStrategyForm.energyType === "WATER"
            ? "m³"
            : "";
        this.editStrategyForm.rules.forEach((rule) => {
          rule.unit = unit;
        });
        this.editStrategyForm.peakValleyRules.forEach((rule) => {
          rule.unit = unit;
        });
      }
    },
  },
  mounted() {
    this.fetchStrategyList(this.activeMenu); // 初始化时加载策略列表
  },

  computed: {
    apiModule() {
      return apiModule
    },
    // 计算当前页显示的数据
    currentTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.strategyList.slice(start, end);
    },
  },
  setup() {
    // 查询对应策略的规则
    async function fetchStrategyRules(strategyId, type) {
      this.loadingRuleId = strategyId; // 设置当前加载的策略 ID
      try {
        const queryString = new URLSearchParams({
          strategyId: strategyId,
          type: type,
        }).toString();

        const response = await fetch(
          `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.strategyRule}?${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("网络响应不是 OK");
        }

        const rules = await response.json();

        // 确保策略列表中存在该策略
        const strategyIndex = this.strategyList.findIndex(
          (item) => item.id === strategyId
        );
        if (strategyIndex !== -1) {
          // 直接更新策略列表中的相应策略规则
          this.strategyList[strategyIndex].rules = rules.data;
        }
      } catch (error) {
        console.error("获取策略规则失败:", error);
      } finally {
        // 清除加载标志
        this.loadingRuleId = null; // 清除加载标志
      }
    }

    return {
      fetchStrategyRules,
    };
  },
  methods: {
    /**
     * 策略重算
     */
    //弹窗
    showRerunEditDialog(){
      this.runDialogVisible = true
    },
    // 日期变化后的回调函数
    handleDateChange(value) {
      if (value){
        let date = this.getPreviousDay(value[1])
        this.runDialogText = "重算范围:"+value[0]+"~"+date
        if (date===value[0]){
          ElMessage.error("重算范围需要最少一天")
        }
        // 检查开始日期是否大于结束日期
        if (new Date(value[0]) > new Date(date)) {
          ElMessage.error('重算范围开始时间不能大于结束时间');
          // 重置日期范围
          this.dateRange = [];
        }
      }else {
        this.runDialogText = "重算范围:"
      }

    },
    getPreviousDay(dateArray) {
      // 获取第二个日期
      const secondDate = new Date(dateArray);

      // 计算第二个日期的前一天
      const previousDate = new Date(secondDate);
      previousDate.setDate(secondDate.getDate() - 1);

      // 格式化前一天的日期为 YYYY-MM-DD
      const year = previousDate.getFullYear();
      const month = String(previousDate.getMonth() + 1).padStart(2, '0');
      const day = String(previousDate.getDate()).padStart(2, '0');

      const previousDay = `${year}-${month}-${day}`;
      return previousDay;
    },
    async rerunPeakValleyLevel(){
      if (!this.dateRange){
        ElMessage.error("请选择正确的时间！")
        return null;
      }
      let date = this.getPreviousDay(this.dateRange[1])
      if (date===this.dateRange[0]){
        ElMessage.error("重算范围需要最少一天")
        return null;
      }

      this.progressStatus = "success"
      this.isDisabled = true
      // 显示进度条并重置进度
      this.isLoading = true;
      this.progress = 0;
      let params = {
        strategyId:this.currentRowData.id,
        startTime:this.dateRange[0]+" 00:00:00",
        endTime:date+" 23:59:00"
      }
      let interval = "";
      try {
        // 模拟进度增加
        interval = setInterval(() => {
          if (this.progress < 90) {
            this.progress += 10;
          }
        }, 500);
        // 使用 axios 发送 GET 请求
        const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.rerunPeakValleyLevel}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          params: params
        });
        // 请求成功后设置进度为100%
        this.progress = 100;
        ElMessage.success(response.data.message)
        return response.data.data;
      } catch (err) {
        this.progressStatus = "exception"
        interval = setInterval(() => {
          if (this.progress < 90) {
            this.progress += 10;
          }
        }, 500);
        setTimeout(() => {
          ElMessage.error("重算失败")
        }, 1000); // 延迟一会儿隐藏进度条，给用户展示100%进度的时间

        console.error("Error fetching peak valley level data:", err);
        throw err;
      }finally {
        // 延迟隐藏进度条并关闭弹窗
        setTimeout(() => {
          this.isLoading = false;
          this.runDialogVisible = false;
          this.isDisabled = false
        }, 1000); // 延迟一会儿隐藏进度条，给用户展示100%进度的时间

        clearInterval(interval); // 停止模拟进度增加
      }
    },

    /**
     * 绑定分组
     * @returns {*[]}
     */
    //查询全部分组
    async getAllLocation(){
      try {
        // 使用 axios 发送 GET 请求
        const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllLocation}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // 直接访问 response.data，因为 axios 自动解析 JSON
        this.customRegionData = response.data.data.map(item => ({
          key: item.id,      // 将 id 映射为 key
          label: item.name   // 将 name 映射为 label
        }));
        return response.data.data;
      } catch (err) {
        console.error("Error fetching peak valley level data:", err);
        throw err;
      }
    },
    //分组绑定策略
    async getCustomRegionStrategy(params){
      try {
        // 使用 axios 发送 GET 请求
        const response = await axios.post(`${apiModule.apiBaseUrl}${apiModule.endpoints.getCustomRegionStrategy}`, params,{
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.data.message === '绑定成功') {
          ElMessage.success(response.data.message)
        }else {
          ElMessage.error(response.data.message);
        }

        return response.data.data;
      } catch (err) {
        console.error("Error fetching peak valley level data:", err);
        throw err;
      }
    },
    // 查询策略绑定的分组id集合
    async queryCustomRegionStrategy(params){
      try {
        // 使用 axios 发送 GET 请求
        const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryCustomRegionStrategy}`,{
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            "strategyId": params
          }
        });
        return response.data.data;
      } catch (err) {
        console.error("Error fetching peak valley level data:", err);
        throw err;
      }
    },
    //绑定分组弹窗显示
    showCustomRegionDialog(){
      this.isBatchAddDialogCustomRegion = true
      this.getAllLocation();

      setTimeout(() => {
        let data = this.queryCustomRegionStrategy(this.currentRowData.id);
        data.then(r =>{
          this.selectedCustomRegionIds = r

        })
      }, 100); // 延迟100毫秒

    },
    //绑定分组弹窗关闭
    closeBatchAddCustomRegionDialog(){
      this.isBatchAddDialogCustomRegion = false
    },
    //绑定分组弹框确认
    batchAddCustomRegions(){

      let params = this.selectedCustomRegionIds.map(item =>({
        customRegionId: item,
        strategyId: this.currentRowData.id
      }))

      if (params.length>0){
        this.getCustomRegionStrategy(params)
      }else {
        let param = [{
          strategyId:this.currentRowData.id
        }]
        this.getCustomRegionStrategy(param)
      }
      this.isBatchAddDialogCustomRegion = false
    },


    //新建峰谷平规则结束时间
    generateTimeOptions() {
      const times = [];
      // 生成今天的时间段
      for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        times.push(`${hour}:00`, `${hour}:30`);
      }
      // 生成第二天的时间段
      for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        times.push(`第二天-${hour}:00`, `第二天-${hour}:30`);
      }
      return times;
    },
    //新建峰谷平规则开始时间
    generateTimeOptionsTriggerTime() {
      const times = [];
      // 生成今天的时间段
      for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        times.push(`${hour}:00`, `${hour}:30`);
      }
      return times;
    },
    // 切换用能类型
    handleMenuSelect(menuIndex) {
      this.activeMenu = menuIndex;
      this.expandRowKeys = [];  // 清空所有展开行的状态
      this.fetchStrategyList(menuIndex);
    },
    // 查询全部策略
    async fetchStrategyList(energyType) {
      try {
        const response = await fetch(
          `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.strategyAll}?energyType=${energyType}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.strategyList = data.data || [];
        this.totalItems = this.strategyList.length;
      } catch (error) {
        console.error("Error fetching strategies:", error);
        this.$message.error("策略列表获取失败");
      }
    },
    // 页码改变时更新 currentPage
    handlePageChange(page) {
      this.currentPage = page;
    },
    // 展开表格行
    handleExpand(row, expanded) {
      if (expanded && !row.rules) {
        this.loadingRuleId = row.id;
        this.fetchStrategyRules(row.id, row.type).then(() => {
          this.$nextTick(() => {
            this.expandRowKeys = [row.id]; // 将展开的行 id 存储在 expandRowKeys 中
            this.loadingRuleId = null; // 重置加载状态
          });
        });
      }
    },
    //表格当前行的数据
    handleRowClick(selectedRows){
      this.currentRowData = selectedRows
    },
    translateStrategyType(type) {
      switch (type) {
        case "LADDER":
          return "阶梯";
        case "PEAK_VALLEY_LEVEL":
          return "峰谷平";
        case "FIXED":
          return "固定";
        default:
          return type;
      }
    },
    handleStatusChange(strategy) {
      fetch(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.updateStrategy}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          energyType: strategy.energyType,
          id: strategy.id,
          name: strategy.name,
          status: strategy.status === "1" ? "1" : "0",
          type: strategy.type,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          this.$message.success("策略状态更新成功");
        })
        .catch((error) => {
          console.error("Error updating strategy status:", error);
          this.$message.error("策略状态更新失败");
          strategy.status = strategy.status === "1" ? "0" : "1";
        });
    },
    // 打开新建策略弹窗
    showAddDialog() {
      this.newStrategyForm.energyType = this.activeMenu;
      this.addDialogVisible = true;
    },
    // 添加阶梯规则
    addRule() {
      let unit = this.newStrategyForm.energyType === "ELECTRIC" ? "kW·h" : "m³";
      this.newStrategyForm.rules.push({
        name: "",
        triggerValue: "",
        price: "",
        unit: unit,
      });
    },
    removeRule(index) {
      this.newStrategyForm.rules.splice(index, 1);
    },
    // 添加峰谷平规则
    addPeakValleyRule() {
      let unit = this.newStrategyForm.energyType === "ELECTRIC" ? "kW·h" : "m³";
      this.newStrategyForm.peakValleyRules.push({
        name: "",
        triggerTime: "",
        price: "",
        unit: unit,
      });
    },
    removePeakValleyRule(index) {
      this.newStrategyForm.peakValleyRules.splice(index, 1);
    },
    // 新增策略
    async createStrategy() {
      try {
        const response = await fetch(
          `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.insertStrategy}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              strategy: {
                energyType: this.newStrategyForm.energyType.toUpperCase(),
                name: this.newStrategyForm.name,
                status: this.newStrategyForm.enabled === "true" ? "1" : "0",
                type: this.newStrategyForm.type.toUpperCase(),
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        // this.$message.success("策略创建成功");

        // 根据策略类型调用新增策略规则的方法
        const strategyId = data.data; // 获取策略ID
        await this.insertStrategyRules(strategyId); // 调用新增策略规则的方法

        this.addDialogVisible = false;
        // 清空新增策略弹窗数据
        this.newStrategyForm = {
          name: "",
          energyType: "ELECTRIC",
          type: "ladder",
          enabled: false,
          rules: [],
          peakValleyRules: [],
          fixedPrice: "",
        };
      } catch (error) {
        console.error("Error creating strategy:", error);
      }
    },
    // 新增策略规则
    async insertStrategyRules(strategyId, newRules, isEdit = false) {
      try {
        // 根据是否是编辑模式选择规则来源
        const rules = isEdit
          ? this.editStrategyForm.type === "ladder"
            ? newRules.map((rule) => ({
                ladderStrategy: {
                  cost: rule.cost,
                  name: rule.name,
                  strategyId: strategyId,
                  triggerValue: rule.triggerValue,
                },
                strategyType: "LADDER",
              }))
            : this.editStrategyForm.type === "peak_valley_level"
            ? newRules.map((rule) => ({
                peakValleyLevelStrategy: {
                  cost: rule.cost,
                  name: rule.name,
                  strategy_id: strategyId,
                  trigger_time: rule.trigger_time,
                  end_time: rule.end_time
                },
                strategyType: "PEAK_VALLEY_LEVEL",
              }))
            : [
                {
                  fixedStrategy: {
                    cost: this.editStrategyForm.fixedPrice,
                    strategyId: strategyId,
                  },
                  strategyType: "FIXED",
                },
              ]
          : this.newStrategyForm.type === "ladder"
          ? this.newStrategyForm.rules.map((rule) => ({
              ladderStrategy: {
                cost: rule.price,
                name: rule.name,
                strategyId: strategyId,
                triggerValue: rule.triggerValue,
              },
              strategyType: "LADDER",
            }))
          : this.newStrategyForm.type === "peak_valley_level"
          ? this.newStrategyForm.peakValleyRules.map((rule) => ({
              peakValleyLevelStrategy: {
                cost: rule.price,
                name: rule.name,
                strategy_id: strategyId,
                trigger_time: rule.triggerTime,
                end_time: rule.endTime
              },
              strategyType: "PEAK_VALLEY_LEVEL",
            }))
          : [
              {
                fixedStrategy: {
                  cost: this.newStrategyForm.fixedPrice,
                  strategyId: strategyId,
                },
                strategyType: "FIXED",
              },
            ];

        for (const rule of rules) {
          const response = await fetch(
            `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.insertRule}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(rule),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Rule inserted successfully:", result);
          this.fetchStrategyList(this.activeMenu); // 刷新策略列表
          this.$message.success(result.message || "新增策略成功");
        }
      } catch (error) {
        console.error("Error inserting strategy rules:", error);
        this.$message.error("新增策略失败");
      }finally {
        this.fetchStrategyList(this.activeMenu);
      }
    },
    // 处理时间格式显示
    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().replace("T", " ").substring(0, 19);
    },
      // 展示编辑弹窗
    async showEditDialog(strategy) {
      // 检查是否已经加载过规则
      if (!strategy.rules) {
        await this.fetchStrategyRules(strategy.id, strategy.type);
      }
      this.editStrategyForm = {
        ...strategy,
        energyType: strategy.energyType || "ELECTRIC",
        type: strategy.type.toLowerCase() || "ladder",
        enabled: strategy.status === "1" ? "true" : "false",
        rules: strategy.rules || [],
        peakValleyRules: strategy.peakValleyRules || [],
        fixedPrice: strategy.fixedPrice || 0,
      };
      this.editDialogVisible = true; // 显示编辑弹窗
    },
    // 编辑里添加阶梯规则
    addEditRule() {
      let unit =
        this.editStrategyForm.energyType === "ELECTRIC" ? "kW·h" : "m³";
      this.editStrategyForm.rules.push({
        id: null, // 新增的规则没有id
        name: "",
        triggerValue: "",
        price: "",
        unit: unit,
        isNew: true, // 标记为新规则
      });
    },
    // 编辑删除阶梯规则
    async removeEditRule(index) {
      const ruleId = this.editStrategyForm.rules[index].id; // 获取规则ID
      const strategyType = "LADDER"; // 获取策略类型

      try {
        const response = await fetch(
          `${ApiConfig.apiBaseUrl}/${ApiConfig.endpoints.deleteRule}?ruleId=${ruleId}&strategyType=${strategyType}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Rule deleted successfully:", data);
        this.$message.success(data.message);
        this.editStrategyForm.rules.splice(index, 1);
      } catch (error) {
        console.error("Error deleting rule:", error);
        this.$message.error("规则删除失败");
      }
    },
    // 编辑里添加峰谷平规则
    addEditPeakValleyRule() {
      let unit =
        this.editStrategyForm.energyType === "ELECTRIC" ? "kW·h" : "m³";
      this.editStrategyForm.rules.push({
        id: null, // 新增的规则没有id
        name: "",
        triggerTime: "",
        price: "",
        unit: unit,
        isNew: true, // 标记为新规则
      });
    },
    // 编辑删除峰谷平规则
    async removeEditPeakValleyRule(index) {
      const ruleId = this.editStrategyForm.rules[index].id; // 获取规则ID
      const strategyType = "PEAK_VALLEY_LEVEL"; // 峰谷平策略类型

      try {
        const response = await fetch(
          `${ApiConfig.apiBaseUrl}/${ApiConfig.endpoints.deleteRule}?ruleId=${ruleId}&strategyType=${strategyType}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Peak Valley Rule deleted successfully:", data);
        this.$message.success(data.message);
        this.editStrategyForm.rules.splice(index, 1); // 从本地列表中删除规则
      } catch (error) {
        console.error("Error deleting peak valley rule:", error);
        this.$message.error("峰谷平规则删除失败");
      }
    },
    
    // 保存编辑策略
    async saveEditedStrategy() {
      try {
        // 更新策略基础信息
        const strategyResponse = await fetch(
          `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.updateStrategy}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              energyType: this.editStrategyForm.energyType.toUpperCase(),
              id: this.editStrategyForm.id,
              name: this.editStrategyForm.name,
              status: this.editStrategyForm.enabled === "true" ? "1" : "0",
              type: this.editStrategyForm.type.toUpperCase(),
            }),
          }
        );

        if (!strategyResponse.ok) {
          throw new Error("Network response was not ok");
        }
        // 分别处理新增和已有的规则
        const newRules = this.editStrategyForm.rules.filter(
          (rule) => rule.isNew
        );
        const existingRules = this.editStrategyForm.rules.filter(
          (rule) => !rule.isNew
        );
        // 处理已有规则的更新
        for (const rule of existingRules) {
          let ruleData = {};
          let endpoint = `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.updateRule}`;

          if (this.editStrategyForm.type === "fixed") {
            ruleData = {
              fixedStrategy: {
                cost: rule.cost,
                id: rule.id,
                strategyId: this.editStrategyForm.id,
              },
              strategyType: "FIXED",
            };
          } else if (this.editStrategyForm.type === "ladder") {
            ruleData = {
              ladderStrategy: {
                cost: rule.cost,
                id: rule.id,
                name: rule.name,
                strategyId: this.editStrategyForm.id,
                triggerValue: rule.triggerValue,
              },
              strategyType: "LADDER",
            };
          } else if (this.editStrategyForm.type === "peak_valley_level") {
            ruleData = {
              peakValleyLevelStrategy: {
                cost: rule.cost,
                id: rule.id,
                name: rule.name,
                strategyId: this.editStrategyForm.id,
                triggerTime: rule.trigger_time,
                endTime: rule.end_time
              },
              strategyType: "PEAK_VALLEY_LEVEL",
            };
          }

          const ruleResponse = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(ruleData),
          });

          if (!ruleResponse.ok) {
            throw new Error("Failed to update rule");
          }
        }
        // 处理新增规则的插入
        if (newRules.length > 0) {
          await this.insertStrategyRules(
            this.editStrategyForm.id,
            newRules,
            true
          );
        }
        // 清空展开行状态，取消默认展开
        this.expandRowKeys = [];
        this.$message.success("策略更新成功");
        this.editDialogVisible = false;
        this.fetchStrategyList(this.activeMenu); // 刷新策略列表
      } catch (error) {
        console.error("Error updating strategy:", error);
        this.$message.error("策略更新失败");
      }
    },

    // 删除策略方法
    async deleteStrategy(index) {
      const strategyId = this.strategyList[index].id; // 获取策略的ID
      try {
        const response = await fetch(
          `${ApiConfig.apiBaseUrl}/${ApiConfig.endpoints.deleteStrategy}?id=${strategyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Strategy deleted successfully:", data);
        this.$message.success(data.message);
        this.strategyList.splice(index, 1); // 从本地列表中删除策略
        // 更新总条目数
        this.totalItems = this.strategyList.length;

        // 如果当前页已经没有数据了，且当前页不是第一页，则页码减1
        if (this.currentTableData.length === 0 && this.currentPage > 1) {
          this.currentPage--;
        }
      } catch (error) {
        console.error("Error deleting strategy:", error);
        this.$message.error("策略删除失败");
      }
    },
  },
};
</script>

<style scoped>
.energy-report-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.query-container {
  margin-bottom: 20px;
}



.card-header {
  font-weight: bold;
}

.el-form-item.left-align {
  justify-content: flex-start;
}

.button-container {
  width: 100%;
  text-align: right;
  margin: 0px auto 0px auto;
  padding-bottom: 10px;
}
.el-row {
  padding: 10px;
  margin-bottom: 5px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 16px;
}
</style>
